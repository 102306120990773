/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from "@tanstack/react-query";
import { useHappydogsContext, HappydogsContext } from "./happydogsContext";
import type * as Fetcher from "./happydogsFetcher";
import { happydogsFetch } from "./happydogsFetcher";
import type * as Schemas from "./happydogsSchemas";

export type StatisticsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type StatisticsEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_application_statistics
 */
export const fetchStatisticsEndpoint = (variables: StatisticsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.StatisticsResponse, StatisticsEndpointError, undefined, {}, {}, {}>({
    url: "/api/application/statistics",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_application_statistics
 */
export const useStatisticsEndpoint = <TData = Schemas.StatisticsResponse>(
  variables: StatisticsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.StatisticsResponse, StatisticsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.StatisticsResponse, StatisticsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/application/statistics",
      operationId: "statisticsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchStatisticsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllBugsEndpointsError = Fetcher.ErrorWrapper<undefined>;

export type AllBugsEndpointsResponse = Schemas.SupportRequestView[];

export type AllBugsEndpointsVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_application_support_requests_bugs
 */
export const fetchAllBugsEndpoints = (variables: AllBugsEndpointsVariables, signal?: AbortSignal) =>
  happydogsFetch<AllBugsEndpointsResponse, AllBugsEndpointsError, undefined, {}, {}, {}>({
    url: "/api/application/support-requests/bugs",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_application_support_requests_bugs
 */
export const useAllBugsEndpoints = <TData = AllBugsEndpointsResponse>(
  variables: AllBugsEndpointsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllBugsEndpointsResponse, AllBugsEndpointsError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllBugsEndpointsResponse, AllBugsEndpointsError, TData>({
    queryKey: queryKeyFn({
      path: "/api/application/support-requests/bugs",
      operationId: "allBugsEndpoints",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllBugsEndpoints({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllSupportRequestsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllSupportRequestsEndpointResponse = Schemas.SupportRequestView[];

export type AllSupportRequestsEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_application_support_requests_other
 */
export const fetchAllSupportRequestsEndpoint = (variables: AllSupportRequestsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllSupportRequestsEndpointResponse, AllSupportRequestsEndpointError, undefined, {}, {}, {}>({
    url: "/api/application/support-requests/other",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_application_support_requests_other
 */
export const useAllSupportRequestsEndpoint = <TData = AllSupportRequestsEndpointResponse>(
  variables: AllSupportRequestsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllSupportRequestsEndpointResponse, AllSupportRequestsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllSupportRequestsEndpointResponse, AllSupportRequestsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/application/support-requests/other",
      operationId: "allSupportRequestsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllSupportRequestsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllSuggestionsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllSuggestionsEndpointResponse = Schemas.SupportRequestView[];

export type AllSuggestionsEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_application_support_requests_suggestions
 */
export const fetchAllSuggestionsEndpoint = (variables: AllSuggestionsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllSuggestionsEndpointResponse, AllSuggestionsEndpointError, undefined, {}, {}, {}>({
    url: "/api/application/support-requests/suggestions",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_application_support_requests_suggestions
 */
export const useAllSuggestionsEndpoint = <TData = AllSuggestionsEndpointResponse>(
  variables: AllSuggestionsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllSuggestionsEndpointResponse, AllSuggestionsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllSuggestionsEndpointResponse, AllSuggestionsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/application/support-requests/suggestions",
      operationId: "allSuggestionsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllSuggestionsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type HealthTestEndpointError = Fetcher.ErrorWrapper<undefined>;

export type HealthTestEndpointResponse = Schemas.Test[];

export type HealthTestEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_health_tests
 */
export const fetchHealthTestEndpoint = (variables: HealthTestEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<HealthTestEndpointResponse, HealthTestEndpointError, undefined, {}, {}, {}>({
    url: "/api/health/tests",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_health_tests
 */
export const useHealthTestEndpoint = <TData = HealthTestEndpointResponse>(
  variables: HealthTestEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<HealthTestEndpointResponse, HealthTestEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<HealthTestEndpointResponse, HealthTestEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/health/tests",
      operationId: "healthTestEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchHealthTestEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ApplicationCreateAdminUploadAssetTokenEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ApplicationCreateAdminUploadAssetTokenEndpointVariables = {
  body: Schemas.AdminCreateUploadAssetTokenCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_application_admin_create_upload_asset_token
 */
export const fetchApplicationCreateAdminUploadAssetTokenEndpoint = (
  variables: ApplicationCreateAdminUploadAssetTokenEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<
    Schemas.AdminCreateUploadAssetResult,
    ApplicationCreateAdminUploadAssetTokenEndpointError,
    Schemas.AdminCreateUploadAssetTokenCommand,
    {},
    {},
    {}
  >({
    url: "/api/application/admin/create-upload-asset-token",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_application_admin_create_upload_asset_token
 */
export const useApplicationCreateAdminUploadAssetTokenEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AdminCreateUploadAssetResult,
      ApplicationCreateAdminUploadAssetTokenEndpointError,
      ApplicationCreateAdminUploadAssetTokenEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.AdminCreateUploadAssetResult,
    ApplicationCreateAdminUploadAssetTokenEndpointError,
    ApplicationCreateAdminUploadAssetTokenEndpointVariables
  >({
    mutationFn: (variables: ApplicationCreateAdminUploadAssetTokenEndpointVariables) =>
      fetchApplicationCreateAdminUploadAssetTokenEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RemoveSupportRequestEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveSupportRequestEndpointVariables = {
  body: Schemas.RemoveSupportRequestCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_application_support_requests_remove
 */
export const fetchRemoveSupportRequestEndpoint = (variables: RemoveSupportRequestEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveSupportRequestEndpointError, Schemas.RemoveSupportRequestCommand, {}, {}, {}>({
    url: "/api/application/support-requests/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_application_support_requests_remove
 */
export const useRemoveSupportRequestEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveSupportRequestEndpointError, RemoveSupportRequestEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveSupportRequestEndpointError, RemoveSupportRequestEndpointVariables>({
    mutationFn: (variables: RemoveSupportRequestEndpointVariables) =>
      fetchRemoveSupportRequestEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SendBugRequestEndpointError = Fetcher.ErrorWrapper<undefined>;

export type SendBugRequestEndpointVariables = {
  body: Schemas.SendSupportRequestCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_application_support_requests_bug
 */
export const fetchSendBugRequestEndpoint = (variables: SendBugRequestEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, SendBugRequestEndpointError, Schemas.SendSupportRequestCommand, {}, {}, {}>({
    url: "/api/application/support-requests/bug",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_application_support_requests_bug
 */
export const useSendBugRequestEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, SendBugRequestEndpointError, SendBugRequestEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, SendBugRequestEndpointError, SendBugRequestEndpointVariables>({
    mutationFn: (variables: SendBugRequestEndpointVariables) => fetchSendBugRequestEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SendSupportRequestEndpointError = Fetcher.ErrorWrapper<undefined>;

export type SendSupportRequestEndpointVariables = {
  body: Schemas.SendSupportRequestCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_application_support_requests_support
 */
export const fetchSendSupportRequestEndpoint = (variables: SendSupportRequestEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, SendSupportRequestEndpointError, Schemas.SendSupportRequestCommand, {}, {}, {}>({
    url: "/api/application/support-requests/support",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_application_support_requests_support
 */
export const useSendSupportRequestEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SendSupportRequestEndpointError, SendSupportRequestEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, SendSupportRequestEndpointError, SendSupportRequestEndpointVariables>({
    mutationFn: (variables: SendSupportRequestEndpointVariables) => fetchSendSupportRequestEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SendSuggestionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type SendSuggestionEndpointVariables = {
  body: Schemas.SendSupportRequestCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_application_support_requests_suggestion
 */
export const fetchSendSuggestionEndpoint = (variables: SendSuggestionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, SendSuggestionEndpointError, Schemas.SendSupportRequestCommand, {}, {}, {}>({
    url: "/api/application/support-requests/suggestion",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_application_support_requests_suggestion
 */
export const useSendSuggestionEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, SendSuggestionEndpointError, SendSuggestionEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, SendSuggestionEndpointError, SendSuggestionEndpointVariables>({
    mutationFn: (variables: SendSuggestionEndpointVariables) => fetchSendSuggestionEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AllArticlesEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllArticlesEndpointResponse = Schemas.ArticlesView[];

export type AllArticlesEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_article_all_public
 */
export const fetchAllArticlesEndpoint = (variables: AllArticlesEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllArticlesEndpointResponse, AllArticlesEndpointError, undefined, {}, {}, {}>({
    url: "/api/article/all/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_article_all_public
 */
export const useAllArticlesEndpoint = <TData = AllArticlesEndpointResponse>(
  variables: AllArticlesEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllArticlesEndpointResponse, AllArticlesEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllArticlesEndpointResponse, AllArticlesEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/article/all/public",
      operationId: "allArticlesEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllArticlesEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ChangeArticleEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeArticleEndpointVariables = {
  body: Schemas.ChangeArticleCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_article_change
 */
export const fetchChangeArticleEndpoint = (variables: ChangeArticleEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeArticleEndpointError, Schemas.ChangeArticleCommand, {}, {}, {}>({
    url: "/api/article/change",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_article_change
 */
export const useChangeArticleEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeArticleEndpointError, ChangeArticleEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeArticleEndpointError, ChangeArticleEndpointVariables>({
    mutationFn: (variables: ChangeArticleEndpointVariables) => fetchChangeArticleEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveArticleEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveArticleEndpointVariables = {
  body: Schemas.RemoveArticleCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_article_remove
 */
export const fetchRemoveArticleEndpoint = (variables: RemoveArticleEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveArticleEndpointError, Schemas.RemoveArticleCommand, {}, {}, {}>({
    url: "/api/article/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_article_remove
 */
export const useRemoveArticleEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RemoveArticleEndpointError, RemoveArticleEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveArticleEndpointError, RemoveArticleEndpointVariables>({
    mutationFn: (variables: RemoveArticleEndpointVariables) => fetchRemoveArticleEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostArticleEndpointError = Fetcher.ErrorWrapper<undefined>;

export type PostArticleEndpointVariables = {
  body: Schemas.PostArticleCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_article_add
 */
export const fetchPostArticleEndpoint = (variables: PostArticleEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.ArticleResponse, PostArticleEndpointError, Schemas.PostArticleCommand, {}, {}, {}>({
    url: "/api/article/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_article_add
 */
export const usePostArticleEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.ArticleResponse, PostArticleEndpointError, PostArticleEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.ArticleResponse, PostArticleEndpointError, PostArticleEndpointVariables>({
    mutationFn: (variables: PostArticleEndpointVariables) => fetchPostArticleEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AllBreedersEndpointQueryParams = {
  /**
   * @format uuid
   */
  cursor?: string;
  /**
   * @format int32
   */
  limit?: number;
  breeds?: string;
};

export type AllBreedersEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllBreedersEndpointResponse = Schemas.BreederView[];

export type AllBreedersEndpointVariables = {
  queryParams?: AllBreedersEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeders_public
 */
export const fetchAllBreedersEndpoint = (variables: AllBreedersEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllBreedersEndpointResponse, AllBreedersEndpointError, undefined, {}, AllBreedersEndpointQueryParams, {}>({
    url: "/api/breeders/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeders_public
 */
export const useAllBreedersEndpoint = <TData = AllBreedersEndpointResponse>(
  variables: AllBreedersEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllBreedersEndpointResponse, AllBreedersEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllBreedersEndpointResponse, AllBreedersEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeders/public",
      operationId: "allBreedersEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllBreedersEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllBreedersRandomizedEndpointQueryParams = {
  /**
   * @format int32
   */
  page?: number;
  /**
   * @format int32
   */
  limit?: number;
  breeds?: string;
};

export type AllBreedersRandomizedEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllBreedersRandomizedEndpointResponse = Schemas.BreederView[];

export type AllBreedersRandomizedEndpointVariables = {
  queryParams?: AllBreedersRandomizedEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeders_randomized_public
 */
export const fetchAllBreedersRandomizedEndpoint = (variables: AllBreedersRandomizedEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    AllBreedersRandomizedEndpointResponse,
    AllBreedersRandomizedEndpointError,
    undefined,
    {},
    AllBreedersRandomizedEndpointQueryParams,
    {}
  >({
    url: "/api/breeders/randomized/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeders_randomized_public
 */
export const useAllBreedersRandomizedEndpoint = <TData = AllBreedersRandomizedEndpointResponse>(
  variables: AllBreedersRandomizedEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllBreedersRandomizedEndpointResponse, AllBreedersRandomizedEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllBreedersRandomizedEndpointResponse, AllBreedersRandomizedEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeders/randomized/public",
      operationId: "allBreedersRandomizedEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllBreedersRandomizedEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateBreederEndpointPathParams = {
  /**
   * @format uuid
   */
  breederId: string;
};

export type GetPrivateBreederEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateBreederEndpointVariables = {
  pathParams: GetPrivateBreederEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederId_private
 */
export const fetchGetPrivateBreederEndpoint = (variables: GetPrivateBreederEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.BreederView, GetPrivateBreederEndpointError, undefined, {}, {}, GetPrivateBreederEndpointPathParams>({
    url: "/api/breeder/{breederId}/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederId_private
 */
export const useGetPrivateBreederEndpoint = <TData = Schemas.BreederView>(
  variables: GetPrivateBreederEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BreederView, GetPrivateBreederEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.BreederView, GetPrivateBreederEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederId}/private",
      operationId: "getPrivateBreederEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateBreederEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetBreederBySlugEndpointPathParams = {
  breederSlug: string;
};

export type GetBreederBySlugEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetBreederBySlugEndpointVariables = {
  pathParams: GetBreederBySlugEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederSlug_public
 */
export const fetchGetBreederBySlugEndpoint = (variables: GetBreederBySlugEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.BreederView, GetBreederBySlugEndpointError, undefined, {}, {}, GetBreederBySlugEndpointPathParams>({
    url: "/api/breeder/{breederSlug}/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederSlug_public
 */
export const useGetBreederBySlugEndpoint = <TData = Schemas.BreederView>(
  variables: GetBreederBySlugEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BreederView, GetBreederBySlugEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.BreederView, GetBreederBySlugEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederSlug}/public",
      operationId: "getBreederBySlugEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetBreederBySlugEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetBreederSlugListEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetBreederSlugListEndpointResponse = string[];

export type GetBreederSlugListEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_slug_list
 */
export const fetchGetBreederSlugListEndpoint = (variables: GetBreederSlugListEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<GetBreederSlugListEndpointResponse, GetBreederSlugListEndpointError, undefined, {}, {}, {}>({
    url: "/api/breeder/slug-list",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_slug_list
 */
export const useGetBreederSlugListEndpoint = <TData = GetBreederSlugListEndpointResponse>(
  variables: GetBreederSlugListEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetBreederSlugListEndpointResponse, GetBreederSlugListEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetBreederSlugListEndpointResponse, GetBreederSlugListEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/slug-list",
      operationId: "getBreederSlugListEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetBreederSlugListEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateMemberBreederEndpointPathParams = {
  /**
   * @format uuid
   */
  memberId: string;
};

export type GetPrivateMemberBreederEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateMemberBreederEndpointVariables = {
  pathParams: GetPrivateMemberBreederEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_member_memberId_breeder_private
 */
export const fetchGetPrivateMemberBreederEndpoint = (variables: GetPrivateMemberBreederEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.BreederView, GetPrivateMemberBreederEndpointError, undefined, {}, {}, GetPrivateMemberBreederEndpointPathParams>({
    url: "/api/member/{memberId}/breeder/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_member_memberId_breeder_private
 */
export const useGetPrivateMemberBreederEndpoint = <TData = Schemas.BreederView>(
  variables: GetPrivateMemberBreederEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.BreederView, GetPrivateMemberBreederEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.BreederView, GetPrivateMemberBreederEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/member/{memberId}/breeder/private",
      operationId: "getPrivateMemberBreederEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateMemberBreederEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateContactListEndpointPathParams = {
  /**
   * @format uuid
   */
  breederId: string;
};

export type GetPrivateContactListEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateContactListEndpointVariables = {
  pathParams: GetPrivateContactListEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederId_contact_list_private
 */
export const fetchGetPrivateContactListEndpoint = (variables: GetPrivateContactListEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.ContactListView, GetPrivateContactListEndpointError, undefined, {}, {}, GetPrivateContactListEndpointPathParams>({
    url: "/api/breeder/{breederId}/contact-list/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederId_contact_list_private
 */
export const useGetPrivateContactListEndpoint = <TData = Schemas.ContactListView>(
  variables: GetPrivateContactListEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ContactListView, GetPrivateContactListEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.ContactListView, GetPrivateContactListEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederId}/contact-list/private",
      operationId: "getPrivateContactListEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateContactListEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateBreederImagesPathParams = {
  /**
   * @format uuid
   */
  breederId: string;
};

export type GetPrivateBreederImagesError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateBreederImagesResponse = Schemas.ImageView[];

export type GetPrivateBreederImagesVariables = {
  pathParams: GetPrivateBreederImagesPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederId_private_images
 */
export const fetchGetPrivateBreederImages = (variables: GetPrivateBreederImagesVariables, signal?: AbortSignal) =>
  happydogsFetch<GetPrivateBreederImagesResponse, GetPrivateBreederImagesError, undefined, {}, {}, GetPrivateBreederImagesPathParams>({
    url: "/api/breeder/{breederId}/private/images",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederId_private_images
 */
export const useGetPrivateBreederImages = <TData = GetPrivateBreederImagesResponse>(
  variables: GetPrivateBreederImagesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPrivateBreederImagesResponse, GetPrivateBreederImagesError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetPrivateBreederImagesResponse, GetPrivateBreederImagesError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederId}/private/images",
      operationId: "getPrivateBreederImages",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateBreederImages({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ChangeBreederAboutUsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederAboutUsEndpointVariables = {
  body: Schemas.ChangeBreederAboutUsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_change_about_us
 */
export const fetchChangeBreederAboutUsEndpoint = (variables: ChangeBreederAboutUsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederAboutUsEndpointError, Schemas.ChangeBreederAboutUsCommand, {}, {}, {}>({
    url: "/api/breeder/change-about-us",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_change_about_us
 */
export const useChangeBreederAboutUsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederAboutUsEndpointError, ChangeBreederAboutUsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederAboutUsEndpointError, ChangeBreederAboutUsEndpointVariables>({
    mutationFn: (variables: ChangeBreederAboutUsEndpointVariables) =>
      fetchChangeBreederAboutUsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederBreedsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederBreedsEndpointVariables = {
  body: Schemas.ChangeBreederBreedsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_change_breeds
 */
export const fetchChangeBreederBreedsEndpoint = (variables: ChangeBreederBreedsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederBreedsEndpointError, Schemas.ChangeBreederBreedsCommand, {}, {}, {}>({
    url: "/api/breeder/change-breeds",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_change_breeds
 */
export const useChangeBreederBreedsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederBreedsEndpointError, ChangeBreederBreedsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederBreedsEndpointError, ChangeBreederBreedsEndpointVariables>({
    mutationFn: (variables: ChangeBreederBreedsEndpointVariables) => fetchChangeBreederBreedsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederKennelNameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederKennelNameEndpointVariables = {
  body: Schemas.ChangeBreederKennelNameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_change_kennel_name
 */
export const fetchChangeBreederKennelNameEndpoint = (variables: ChangeBreederKennelNameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederKennelNameEndpointError, Schemas.ChangeBreederKennelNameCommand, {}, {}, {}>({
    url: "/api/breeder/change-kennel-name",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_change_kennel_name
 */
export const useChangeBreederKennelNameEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederKennelNameEndpointError, ChangeBreederKennelNameEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederKennelNameEndpointError, ChangeBreederKennelNameEndpointVariables>({
    mutationFn: (variables: ChangeBreederKennelNameEndpointVariables) =>
      fetchChangeBreederKennelNameEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederLocationEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederLocationEndpointVariables = {
  body: Schemas.ChangeBreederLocationCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_change_location
 */
export const fetchChangeBreederLocationEndpoint = (variables: ChangeBreederLocationEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederLocationEndpointError, Schemas.ChangeBreederLocationCommand, {}, {}, {}>({
    url: "/api/breeder/change-location",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_change_location
 */
export const useChangeBreederLocationEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederLocationEndpointError, ChangeBreederLocationEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederLocationEndpointError, ChangeBreederLocationEndpointVariables>({
    mutationFn: (variables: ChangeBreederLocationEndpointVariables) =>
      fetchChangeBreederLocationEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederNextPlannedLitterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederNextPlannedLitterEndpointVariables = {
  body: Schemas.ChangeBreederNextPlannedLitterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_change_next_planned_litter
 */
export const fetchChangeBreederNextPlannedLitterEndpoint = (
  variables: ChangeBreederNextPlannedLitterEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ChangeBreederNextPlannedLitterEndpointError, Schemas.ChangeBreederNextPlannedLitterCommand, {}, {}, {}>({
    url: "/api/breeder/change-next-planned-litter",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_change_next_planned_litter
 */
export const useChangeBreederNextPlannedLitterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederNextPlannedLitterEndpointError, ChangeBreederNextPlannedLitterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederNextPlannedLitterEndpointError, ChangeBreederNextPlannedLitterEndpointVariables>({
    mutationFn: (variables: ChangeBreederNextPlannedLitterEndpointVariables) =>
      fetchChangeBreederNextPlannedLitterEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type AddBreederQuestionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddBreederQuestionEndpointVariables = {
  body: Schemas.AddBreederQuestionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_questions_add
 */
export const fetchAddBreederQuestionEndpoint = (variables: AddBreederQuestionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddBreederQuestionEndpointError, Schemas.AddBreederQuestionCommand, {}, {}, {}>({
    url: "/api/breeder/questions/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_questions_add
 */
export const useAddBreederQuestionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddBreederQuestionEndpointError, AddBreederQuestionEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddBreederQuestionEndpointError, AddBreederQuestionEndpointVariables>({
    mutationFn: (variables: AddBreederQuestionEndpointVariables) => fetchAddBreederQuestionEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveBreederQuestionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveBreederQuestionEndpointVariables = {
  body: Schemas.RemoveBreederQuestionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_questions_remove
 */
export const fetchRemoveBreederQuestionEndpoint = (variables: RemoveBreederQuestionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveBreederQuestionEndpointError, Schemas.RemoveBreederQuestionCommand, {}, {}, {}>({
    url: "/api/breeder/questions/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_questions_remove
 */
export const useRemoveBreederQuestionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveBreederQuestionEndpointError, RemoveBreederQuestionEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveBreederQuestionEndpointError, RemoveBreederQuestionEndpointVariables>({
    mutationFn: (variables: RemoveBreederQuestionEndpointVariables) =>
      fetchRemoveBreederQuestionEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederQuestionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederQuestionEndpointVariables = {
  body: Schemas.ChangeBreederQuestionCommans;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_questions_change
 */
export const fetchChangeBreederQuestionEndpoint = (variables: ChangeBreederQuestionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederQuestionEndpointError, Schemas.ChangeBreederQuestionCommans, {}, {}, {}>({
    url: "/api/breeder/questions/change",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_questions_change
 */
export const useChangeBreederQuestionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederQuestionEndpointError, ChangeBreederQuestionEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederQuestionEndpointError, ChangeBreederQuestionEndpointVariables>({
    mutationFn: (variables: ChangeBreederQuestionEndpointVariables) =>
      fetchChangeBreederQuestionEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederVisionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederVisionEndpointVariables = {
  body: Schemas.ChangeVisionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_change_vision
 */
export const fetchChangeBreederVisionEndpoint = (variables: ChangeBreederVisionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederVisionEndpointError, Schemas.ChangeVisionCommand, {}, {}, {}>({
    url: "/api/breeder/change-vision",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_change_vision
 */
export const useChangeBreederVisionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederVisionEndpointError, ChangeBreederVisionEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederVisionEndpointError, ChangeBreederVisionEndpointVariables>({
    mutationFn: (variables: ChangeBreederVisionEndpointVariables) => fetchChangeBreederVisionEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddBreederChapterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddBreederChapterEndpointVariables = {
  body: Schemas.AddBreederChapterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_chapters_add
 */
export const fetchAddBreederChapterEndpoint = (variables: AddBreederChapterEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddBreederChapterEndpointError, Schemas.AddBreederChapterCommand, {}, {}, {}>({
    url: "/api/breeder/chapters/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_chapters_add
 */
export const useAddBreederChapterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddBreederChapterEndpointError, AddBreederChapterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddBreederChapterEndpointError, AddBreederChapterEndpointVariables>({
    mutationFn: (variables: AddBreederChapterEndpointVariables) => fetchAddBreederChapterEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeBreederChapterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeBreederChapterEndpointVariables = {
  body: Schemas.ChangeBreederChapterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_chapters_change
 */
export const fetchChangeBreederChapterEndpoint = (variables: ChangeBreederChapterEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeBreederChapterEndpointError, Schemas.ChangeBreederChapterCommand, {}, {}, {}>({
    url: "/api/breeder/chapters/change",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_chapters_change
 */
export const useChangeBreederChapterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeBreederChapterEndpointError, ChangeBreederChapterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeBreederChapterEndpointError, ChangeBreederChapterEndpointVariables>({
    mutationFn: (variables: ChangeBreederChapterEndpointVariables) =>
      fetchChangeBreederChapterEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveBreederChapterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveBreederChapterEndpointVariables = {
  body: Schemas.RemoveBreederChapterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_chapters_remove
 */
export const fetchRemoveBreederChapterEndpoint = (variables: RemoveBreederChapterEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveBreederChapterEndpointError, Schemas.RemoveBreederChapterCommand, {}, {}, {}>({
    url: "/api/breeder/chapters/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_chapters_remove
 */
export const useRemoveBreederChapterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveBreederChapterEndpointError, RemoveBreederChapterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveBreederChapterEndpointError, RemoveBreederChapterEndpointVariables>({
    mutationFn: (variables: RemoveBreederChapterEndpointVariables) =>
      fetchRemoveBreederChapterEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddBreederUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddBreederUploadedImageEndpointVariables = {
  body: Schemas.AddBreederUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_add_uploaded_image
 */
export const fetchAddBreederUploadedImageEndpoint = (variables: AddBreederUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddBreederUploadedImageEndpointError, Schemas.AddBreederUploadedImageCommand, {}, {}, {}>({
    url: "/api/breeder/add-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_add_uploaded_image
 */
export const useAddBreederUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddBreederUploadedImageEndpointError, AddBreederUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddBreederUploadedImageEndpointError, AddBreederUploadedImageEndpointVariables>({
    mutationFn: (variables: AddBreederUploadedImageEndpointVariables) =>
      fetchAddBreederUploadedImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveBreederUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveBreederUploadedImageEndpointVariables = {
  body: Schemas.RemoveBreederUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_remove_uploaded_image
 */
export const fetchRemoveBreederUploadedImageEndpoint = (variables: RemoveBreederUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveBreederUploadedImageEndpointError, Schemas.RemoveBreederUploadedImageCommand, {}, {}, {}>({
    url: "/api/breeder/remove-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_remove_uploaded_image
 */
export const useRemoveBreederUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveBreederUploadedImageEndpointError, RemoveBreederUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveBreederUploadedImageEndpointError, RemoveBreederUploadedImageEndpointVariables>({
    mutationFn: (variables: RemoveBreederUploadedImageEndpointVariables) =>
      fetchRemoveBreederUploadedImageEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type AddBreederBannerImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddBreederBannerImageEndpointVariables = {
  body: Schemas.AddBreederBannerImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_add_banner
 */
export const fetchAddBreederBannerImageEndpoint = (variables: AddBreederBannerImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddBreederBannerImageEndpointError, Schemas.AddBreederBannerImageCommand, {}, {}, {}>({
    url: "/api/breeder/add-banner",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_add_banner
 */
export const useAddBreederBannerImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddBreederBannerImageEndpointError, AddBreederBannerImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddBreederBannerImageEndpointError, AddBreederBannerImageEndpointVariables>({
    mutationFn: (variables: AddBreederBannerImageEndpointVariables) =>
      fetchAddBreederBannerImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterBreederEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterBreederEndpointVariables = {
  body: Schemas.RegisterBreederCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_register
 */
export const fetchRegisterBreederEndpoint = (variables: RegisterBreederEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.RegisterBreederResponse, RegisterBreederEndpointError, Schemas.RegisterBreederCommand, {}, {}, {}>({
    url: "/api/breeder/register",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_register
 */
export const useRegisterBreederEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.RegisterBreederResponse, RegisterBreederEndpointError, RegisterBreederEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.RegisterBreederResponse, RegisterBreederEndpointError, RegisterBreederEndpointVariables>({
    mutationFn: (variables: RegisterBreederEndpointVariables) => fetchRegisterBreederEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveBreederEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveBreederEndpointVariables = {
  body: Schemas.RemoveBreederCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_breeder_remove
 */
export const fetchRemoveBreederEndpoint = (variables: RemoveBreederEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveBreederEndpointError, Schemas.RemoveBreederCommand, {}, {}, {}>({
    url: "/api/breeder/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_breeder_remove
 */
export const useRemoveBreederEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RemoveBreederEndpointError, RemoveBreederEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveBreederEndpointError, RemoveBreederEndpointVariables>({
    mutationFn: (variables: RemoveBreederEndpointVariables) => fetchRemoveBreederEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ContactListAddContactMemberEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListAddContactMemberEndpointVariables = {
  body: Schemas.ContactListAddMemberCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_add_member_contact
 */
export const fetchContactListAddContactMemberEndpoint = (variables: ContactListAddContactMemberEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.ContactListAddContactResponse,
    ContactListAddContactMemberEndpointError,
    Schemas.ContactListAddMemberCommand,
    {},
    {},
    {}
  >({
    url: "/api/contact-list/add-member-contact",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_add_member_contact
 */
export const useContactListAddContactMemberEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContactListAddContactResponse,
      ContactListAddContactMemberEndpointError,
      ContactListAddContactMemberEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.ContactListAddContactResponse,
    ContactListAddContactMemberEndpointError,
    ContactListAddContactMemberEndpointVariables
  >({
    mutationFn: (variables: ContactListAddContactMemberEndpointVariables) =>
      fetchContactListAddContactMemberEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListWithdrawContactMemberEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListWithdrawContactMemberEndpointVariables = {
  body: Schemas.ContactListWithdrawContactCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_withdraw
 */
export const fetchContactListWithdrawContactMemberEndpoint = (
  variables: ContactListWithdrawContactMemberEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListWithdrawContactMemberEndpointError, Schemas.ContactListWithdrawContactCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/withdraw",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_withdraw
 */
export const useContactListWithdrawContactMemberEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ContactListWithdrawContactMemberEndpointError,
      ContactListWithdrawContactMemberEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ContactListWithdrawContactMemberEndpointError,
    ContactListWithdrawContactMemberEndpointVariables
  >({
    mutationFn: (variables: ContactListWithdrawContactMemberEndpointVariables) =>
      fetchContactListWithdrawContactMemberEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListNewContactEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListNewContactEndpointVariables = {
  body: Schemas.ContactListNewContactCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_add_new_contact
 */
export const fetchContactListNewContactEndpoint = (variables: ContactListNewContactEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ContactListNewContactEndpointError, Schemas.ContactListNewContactCommand, {}, {}, {}>({
    url: "/api/contact-list/add-new-contact",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_add_new_contact
 */
export const useContactListNewContactEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListNewContactEndpointError, ContactListNewContactEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListNewContactEndpointError, ContactListNewContactEndpointVariables>({
    mutationFn: (variables: ContactListNewContactEndpointVariables) =>
      fetchContactListNewContactEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ContactListApproveContactEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListApproveContactEndpointVariables = {
  body: Schemas.ContactListApproveContactCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_approve
 */
export const fetchContactListApproveContactEndpoint = (variables: ContactListApproveContactEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ContactListApproveContactEndpointError, Schemas.ContactListApproveContactCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/approve",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_approve
 */
export const useContactListApproveContactEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListApproveContactEndpointError, ContactListApproveContactEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListApproveContactEndpointError, ContactListApproveContactEndpointVariables>({
    mutationFn: (variables: ContactListApproveContactEndpointVariables) =>
      fetchContactListApproveContactEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListRejectContactEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListRejectContactEndpointVariables = {
  body: Schemas.ContactListRejectContactCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_reject
 */
export const fetchContactListRejectContactEndpoint = (variables: ContactListRejectContactEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ContactListRejectContactEndpointError, Schemas.ContactListRejectContactCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/reject",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_reject
 */
export const useContactListRejectContactEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListRejectContactEndpointError, ContactListRejectContactEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListRejectContactEndpointError, ContactListRejectContactEndpointVariables>({
    mutationFn: (variables: ContactListRejectContactEndpointVariables) =>
      fetchContactListRejectContactEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListRemoveContactEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListRemoveContactEndpointVariables = {
  body: Schemas.ContactListRejectContactCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_remove
 */
export const fetchContactListRemoveContactEndpoint = (variables: ContactListRemoveContactEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ContactListRemoveContactEndpointError, Schemas.ContactListRejectContactCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_remove
 */
export const useContactListRemoveContactEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListRemoveContactEndpointError, ContactListRemoveContactEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListRemoveContactEndpointError, ContactListRemoveContactEndpointVariables>({
    mutationFn: (variables: ContactListRemoveContactEndpointVariables) =>
      fetchContactListRemoveContactEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListChangeContactNameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListChangeContactNameEndpointVariables = {
  body: Schemas.ContactListChangeContactNameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_change_name
 */
export const fetchContactListChangeContactNameEndpoint = (variables: ContactListChangeContactNameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ContactListChangeContactNameEndpointError, Schemas.ContactListChangeContactNameCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/change-name",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_change_name
 */
export const useContactListChangeContactNameEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListChangeContactNameEndpointError, ContactListChangeContactNameEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListChangeContactNameEndpointError, ContactListChangeContactNameEndpointVariables>({
    mutationFn: (variables: ContactListChangeContactNameEndpointVariables) =>
      fetchContactListChangeContactNameEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListChangeContactEmailEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListChangeContactEmailEndpointVariables = {
  body: Schemas.ContactListChangeContactEmailCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_change_email
 */
export const fetchContactListChangeContactEmailEndpoint = (
  variables: ContactListChangeContactEmailEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListChangeContactEmailEndpointError, Schemas.ContactListChangeContactEmailCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/change-email",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_change_email
 */
export const useContactListChangeContactEmailEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListChangeContactEmailEndpointError, ContactListChangeContactEmailEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListChangeContactEmailEndpointError, ContactListChangeContactEmailEndpointVariables>({
    mutationFn: (variables: ContactListChangeContactEmailEndpointVariables) =>
      fetchContactListChangeContactEmailEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListChangeContactPhoneEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListChangeContactPhoneEndpointVariables = {
  body: Schemas.ContactListChangeContactPhoneCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_change_phone
 */
export const fetchContactListChangeContactPhoneEndpoint = (
  variables: ContactListChangeContactPhoneEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListChangeContactPhoneEndpointError, Schemas.ContactListChangeContactPhoneCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/change-phone",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_change_phone
 */
export const useContactListChangeContactPhoneEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListChangeContactPhoneEndpointError, ContactListChangeContactPhoneEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListChangeContactPhoneEndpointError, ContactListChangeContactPhoneEndpointVariables>({
    mutationFn: (variables: ContactListChangeContactPhoneEndpointVariables) =>
      fetchContactListChangeContactPhoneEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListChangeContactCommentEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListChangeContactCommentEndpointVariables = {
  body: Schemas.ContactListChangeContactCommentCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_contacts_change_comment
 */
export const fetchContactListChangeContactCommentEndpoint = (
  variables: ContactListChangeContactCommentEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListChangeContactCommentEndpointError, Schemas.ContactListChangeContactCommentCommand, {}, {}, {}>({
    url: "/api/contact-list/contacts/change-comment",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_contacts_change_comment
 */
export const useContactListChangeContactCommentEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ContactListChangeContactCommentEndpointError,
      ContactListChangeContactCommentEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListChangeContactCommentEndpointError, ContactListChangeContactCommentEndpointVariables>({
    mutationFn: (variables: ContactListChangeContactCommentEndpointVariables) =>
      fetchContactListChangeContactCommentEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListAddContactToLitterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListAddContactToLitterEndpointVariables = {
  body: Schemas.ContactListAddContactToLitterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_litter_add_contact
 */
export const fetchContactListAddContactToLitterEndpoint = (
  variables: ContactListAddContactToLitterEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListAddContactToLitterEndpointError, Schemas.ContactListAddContactToLitterCommand, {}, {}, {}>({
    url: "/api/contact-list/litter/add-contact",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_litter_add_contact
 */
export const useContactListAddContactToLitterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListAddContactToLitterEndpointError, ContactListAddContactToLitterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListAddContactToLitterEndpointError, ContactListAddContactToLitterEndpointVariables>({
    mutationFn: (variables: ContactListAddContactToLitterEndpointVariables) =>
      fetchContactListAddContactToLitterEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListRemoveContactToLitterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListRemoveContactToLitterEndpointVariables = {
  body: Schemas.ContactListRemoveContactFromLitterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_litter_remove_contact
 */
export const fetchContactListRemoveContactToLitterEndpoint = (
  variables: ContactListRemoveContactToLitterEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListRemoveContactToLitterEndpointError, Schemas.ContactListRemoveContactFromLitterCommand, {}, {}, {}>({
    url: "/api/contact-list/litter/remove-contact",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_litter_remove_contact
 */
export const useContactListRemoveContactToLitterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ContactListRemoveContactToLitterEndpointError,
      ContactListRemoveContactToLitterEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ContactListRemoveContactToLitterEndpointError,
    ContactListRemoveContactToLitterEndpointVariables
  >({
    mutationFn: (variables: ContactListRemoveContactToLitterEndpointVariables) =>
      fetchContactListRemoveContactToLitterEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListChangeContactLitterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListChangeContactLitterEndpointVariables = {
  body: Schemas.ContactListRemoveContactFromLitterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_litter_change_contact
 */
export const fetchContactListChangeContactLitterEndpoint = (
  variables: ContactListChangeContactLitterEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListChangeContactLitterEndpointError, Schemas.ContactListRemoveContactFromLitterCommand, {}, {}, {}>({
    url: "/api/contact-list/litter/change-contact",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_litter_change_contact
 */
export const useContactListChangeContactLitterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListChangeContactLitterEndpointError, ContactListChangeContactLitterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListChangeContactLitterEndpointError, ContactListChangeContactLitterEndpointVariables>({
    mutationFn: (variables: ContactListChangeContactLitterEndpointVariables) =>
      fetchContactListChangeContactLitterEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListChangeFavoriteContactsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListChangeFavoriteContactsEndpointVariables = {
  body: Schemas.ContactListChangeFavoriteContactCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_change_favorite_contact
 */
export const fetchContactListChangeFavoriteContactsEndpoint = (
  variables: ContactListChangeFavoriteContactsEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ContactListChangeFavoriteContactsEndpointError, Schemas.ContactListChangeFavoriteContactCommand, {}, {}, {}>({
    url: "/api/contact-list/change-favorite-contact",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_change_favorite_contact
 */
export const useContactListChangeFavoriteContactsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ContactListChangeFavoriteContactsEndpointError,
      ContactListChangeFavoriteContactsEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ContactListChangeFavoriteContactsEndpointError,
    ContactListChangeFavoriteContactsEndpointVariables
  >({
    mutationFn: (variables: ContactListChangeFavoriteContactsEndpointVariables) =>
      fetchContactListChangeFavoriteContactsEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ContactListReorderContactsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ContactListReorderContactsEndpointVariables = {
  body: Schemas.ContactListReorderContactsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_contact_list_reorder_contacts
 */
export const fetchContactListReorderContactsEndpoint = (variables: ContactListReorderContactsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ContactListReorderContactsEndpointError, Schemas.ContactListReorderContactsCommand, {}, {}, {}>({
    url: "/api/contact-list/reorder-contacts",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_contact_list_reorder_contacts
 */
export const useContactListReorderContactsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ContactListReorderContactsEndpointError, ContactListReorderContactsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ContactListReorderContactsEndpointError, ContactListReorderContactsEndpointVariables>({
    mutationFn: (variables: ContactListReorderContactsEndpointVariables) =>
      fetchContactListReorderContactsEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PrivateDogEndpointPathParams = {
  /**
   * @format uuid
   */
  dogId: string;
};

export type PrivateDogEndpointError = Fetcher.ErrorWrapper<undefined>;

export type PrivateDogEndpointVariables = {
  pathParams: PrivateDogEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_dog_dogId_private
 */
export const fetchPrivateDogEndpoint = (variables: PrivateDogEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.DogPrivateDetailsView, PrivateDogEndpointError, undefined, {}, {}, PrivateDogEndpointPathParams>({
    url: "/api/dog/{dogId}/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_dog_dogId_private
 */
export const usePrivateDogEndpoint = <TData = Schemas.DogPrivateDetailsView>(
  variables: PrivateDogEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DogPrivateDetailsView, PrivateDogEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.DogPrivateDetailsView, PrivateDogEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/dog/{dogId}/private",
      operationId: "privateDogEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchPrivateDogEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateMemberDogsEndpointPathParams = {
  /**
   * @format uuid
   */
  memberId: string;
};

export type GetPrivateMemberDogsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateMemberDogsEndpointResponse = Schemas.DogPrivateDetailsView[];

export type GetPrivateMemberDogsEndpointVariables = {
  pathParams: GetPrivateMemberDogsEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_member_memberId_dogs_private
 */
export const fetchGetPrivateMemberDogsEndpoint = (variables: GetPrivateMemberDogsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    GetPrivateMemberDogsEndpointResponse,
    GetPrivateMemberDogsEndpointError,
    undefined,
    {},
    {},
    GetPrivateMemberDogsEndpointPathParams
  >({
    url: "/api/member/{memberId}/dogs/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_member_memberId_dogs_private
 */
export const useGetPrivateMemberDogsEndpoint = <TData = GetPrivateMemberDogsEndpointResponse>(
  variables: GetPrivateMemberDogsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPrivateMemberDogsEndpointResponse, GetPrivateMemberDogsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetPrivateMemberDogsEndpointResponse, GetPrivateMemberDogsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/member/{memberId}/dogs/private",
      operationId: "getPrivateMemberDogsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateMemberDogsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllDogsEndpointQueryParams = {
  /**
   * @format uuid
   */
  cursor?: string;
  /**
   * @format int32
   */
  limit?: number;
  breeds?: string;
};

export type AllDogsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllDogsEndpointResponse = Schemas.DogView[];

export type AllDogsEndpointVariables = {
  queryParams?: AllDogsEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_dogs_public
 */
export const fetchAllDogsEndpoint = (variables: AllDogsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllDogsEndpointResponse, AllDogsEndpointError, undefined, {}, AllDogsEndpointQueryParams, {}>({
    url: "/api/dogs/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_dogs_public
 */
export const useAllDogsEndpoint = <TData = AllDogsEndpointResponse>(
  variables: AllDogsEndpointVariables,
  options?: Omit<reactQuery.UseQueryOptions<AllDogsEndpointResponse, AllDogsEndpointError, TData>, "queryKey" | "queryFn" | "initialData">,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllDogsEndpointResponse, AllDogsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/dogs/public",
      operationId: "allDogsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllDogsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllDogsRandomizedEndpointQueryParams = {
  /**
   * @format int32
   */
  page?: number;
  /**
   * @format int32
   */
  limit?: number;
  breeds?: string;
};

export type AllDogsRandomizedEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllDogsRandomizedEndpointResponse = Schemas.DogView[];

export type AllDogsRandomizedEndpointVariables = {
  queryParams?: AllDogsRandomizedEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_dogs_randomized_public
 */
export const fetchAllDogsRandomizedEndpoint = (variables: AllDogsRandomizedEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    AllDogsRandomizedEndpointResponse,
    AllDogsRandomizedEndpointError,
    undefined,
    {},
    AllDogsRandomizedEndpointQueryParams,
    {}
  >({
    url: "/api/dogs/randomized/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_dogs_randomized_public
 */
export const useAllDogsRandomizedEndpoint = <TData = AllDogsRandomizedEndpointResponse>(
  variables: AllDogsRandomizedEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllDogsRandomizedEndpointResponse, AllDogsRandomizedEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllDogsRandomizedEndpointResponse, AllDogsRandomizedEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/dogs/randomized/public",
      operationId: "allDogsRandomizedEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllDogsRandomizedEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllUnverifiedDogsEndpointQueryParams = {
  /**
   * @format int32
   */
  page?: number;
  /**
   * @format int32
   */
  limit?: number;
};

export type AllUnverifiedDogsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllUnverifiedDogsEndpointVariables = {
  queryParams?: AllUnverifiedDogsEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_admin_unverified_dogs
 */
export const fetchAllUnverifiedDogsEndpoint = (variables: AllUnverifiedDogsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.AllUnverifiedDogsResponse,
    AllUnverifiedDogsEndpointError,
    undefined,
    {},
    AllUnverifiedDogsEndpointQueryParams,
    {}
  >({ url: "/api/admin/unverified-dogs", method: "get", ...variables, signal });

/**
 * GET_api_admin_unverified_dogs
 */
export const useAllUnverifiedDogsEndpoint = <TData = Schemas.AllUnverifiedDogsResponse>(
  variables: AllUnverifiedDogsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AllUnverifiedDogsResponse, AllUnverifiedDogsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.AllUnverifiedDogsResponse, AllUnverifiedDogsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/admin/unverified-dogs",
      operationId: "allUnverifiedDogsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllUnverifiedDogsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDogSlugListEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetDogSlugListEndpointResponse = string[];

export type GetDogSlugListEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_dog_slug_list
 */
export const fetchGetDogSlugListEndpoint = (variables: GetDogSlugListEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<GetDogSlugListEndpointResponse, GetDogSlugListEndpointError, undefined, {}, {}, {}>({
    url: "/api/dog/slug-list",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_dog_slug_list
 */
export const useGetDogSlugListEndpoint = <TData = GetDogSlugListEndpointResponse>(
  variables: GetDogSlugListEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetDogSlugListEndpointResponse, GetDogSlugListEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetDogSlugListEndpointResponse, GetDogSlugListEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/dog/slug-list",
      operationId: "getDogSlugListEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetDogSlugListEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDogBySlugEndpointPathParams = {
  dogSlug: string;
};

export type GetDogBySlugEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetDogBySlugEndpointVariables = {
  pathParams: GetDogBySlugEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_dog_dogSlug_public
 */
export const fetchGetDogBySlugEndpoint = (variables: GetDogBySlugEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.DogView, GetDogBySlugEndpointError, undefined, {}, {}, GetDogBySlugEndpointPathParams>({
    url: "/api/dog/{dogSlug}/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_dog_dogSlug_public
 */
export const useGetDogBySlugEndpoint = <TData = Schemas.DogView>(
  variables: GetDogBySlugEndpointVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.DogView, GetDogBySlugEndpointError, TData>, "queryKey" | "queryFn" | "initialData">,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.DogView, GetDogBySlugEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/dog/{dogSlug}/public",
      operationId: "getDogBySlugEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetDogBySlugEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDogByRegistryIdEndpointPathParams = {
  registryId: string;
};

export type GetDogByRegistryIdEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetDogByRegistryIdEndpointVariables = {
  pathParams: GetDogByRegistryIdEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_dog_registryId_by_registry_id_public
 */
export const fetchGetDogByRegistryIdEndpoint = (variables: GetDogByRegistryIdEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.DogView, GetDogByRegistryIdEndpointError, undefined, {}, {}, GetDogByRegistryIdEndpointPathParams>({
    url: "/api/dog/{registryId}/by/registry/id/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_dog_registryId_by_registry_id_public
 */
export const useGetDogByRegistryIdEndpoint = <TData = Schemas.DogView>(
  variables: GetDogByRegistryIdEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DogView, GetDogByRegistryIdEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.DogView, GetDogByRegistryIdEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/dog/{registryId}/by/registry/id/public",
      operationId: "getDogByRegistryIdEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetDogByRegistryIdEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPublicDogByBreederSlugEndpointPathParams = {
  breederSlug: string;
};

export type GetPublicDogByBreederSlugEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicDogByBreederSlugEndpointResponse = Schemas.DogView[];

export type GetPublicDogByBreederSlugEndpointVariables = {
  pathParams: GetPublicDogByBreederSlugEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederSlug_dogs_public
 */
export const fetchGetPublicDogByBreederSlugEndpoint = (variables: GetPublicDogByBreederSlugEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    GetPublicDogByBreederSlugEndpointResponse,
    GetPublicDogByBreederSlugEndpointError,
    undefined,
    {},
    {},
    GetPublicDogByBreederSlugEndpointPathParams
  >({
    url: "/api/breeder/{breederSlug}/dogs/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederSlug_dogs_public
 */
export const useGetPublicDogByBreederSlugEndpoint = <TData = GetPublicDogByBreederSlugEndpointResponse>(
  variables: GetPublicDogByBreederSlugEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPublicDogByBreederSlugEndpointResponse, GetPublicDogByBreederSlugEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetPublicDogByBreederSlugEndpointResponse, GetPublicDogByBreederSlugEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederSlug}/dogs/public",
      operationId: "getPublicDogByBreederSlugEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPublicDogByBreederSlugEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ChangeDogAboutMeEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogAboutMeEndpointVariables = {
  body: Schemas.ChangeDogAboutMeCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_about_me
 */
export const fetchChangeDogAboutMeEndpoint = (variables: ChangeDogAboutMeEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogAboutMeEndpointError, Schemas.ChangeDogAboutMeCommand, {}, {}, {}>({
    url: "/api/dog/change-about-me",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_about_me
 */
export const useChangeDogAboutMeEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogAboutMeEndpointError, ChangeDogAboutMeEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogAboutMeEndpointError, ChangeDogAboutMeEndpointVariables>({
    mutationFn: (variables: ChangeDogAboutMeEndpointVariables) => fetchChangeDogAboutMeEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogBestShowResultEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogBestShowResultEndpointVariables = {
  body: Schemas.ChangeDogBestShowResultCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_best_show_result
 */
export const fetchChangeDogBestShowResultEndpoint = (variables: ChangeDogBestShowResultEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogBestShowResultEndpointError, Schemas.ChangeDogBestShowResultCommand, {}, {}, {}>({
    url: "/api/dog/change-best-show-result",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_best_show_result
 */
export const useChangeDogBestShowResultEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogBestShowResultEndpointError, ChangeDogBestShowResultEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogBestShowResultEndpointError, ChangeDogBestShowResultEndpointVariables>({
    mutationFn: (variables: ChangeDogBestShowResultEndpointVariables) =>
      fetchChangeDogBestShowResultEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogBornAtEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogBornAtEndpointVariables = {
  body: Schemas.ChangeDogBornAtCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_born_at
 */
export const fetchChangeDogBornAtEndpoint = (variables: ChangeDogBornAtEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogBornAtEndpointError, Schemas.ChangeDogBornAtCommand, {}, {}, {}>({
    url: "/api/dog/change-born-at",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_born_at
 */
export const useChangeDogBornAtEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogBornAtEndpointError, ChangeDogBornAtEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogBornAtEndpointError, ChangeDogBornAtEndpointVariables>({
    mutationFn: (variables: ChangeDogBornAtEndpointVariables) => fetchChangeDogBornAtEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogBreedEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogBreedEndpointVariables = {
  body: Schemas.ChangeDogBreedCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_breed
 */
export const fetchChangeDogBreedEndpoint = (variables: ChangeDogBreedEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogBreedEndpointError, Schemas.ChangeDogBreedCommand, {}, {}, {}>({
    url: "/api/dog/change-breed",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_breed
 */
export const useChangeDogBreedEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogBreedEndpointError, ChangeDogBreedEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogBreedEndpointError, ChangeDogBreedEndpointVariables>({
    mutationFn: (variables: ChangeDogBreedEndpointVariables) => fetchChangeDogBreedEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogBreedingAvailabilityEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogBreedingAvailabilityEndpointVariables = {
  body: Schemas.ChangeDogBreedingAvailabilityCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_breeding_availability
 */
export const fetchChangeDogBreedingAvailabilityEndpoint = (
  variables: ChangeDogBreedingAvailabilityEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ChangeDogBreedingAvailabilityEndpointError, Schemas.ChangeDogBreedingAvailabilityCommand, {}, {}, {}>({
    url: "/api/dog/change-breeding-availability",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_breeding_availability
 */
export const useChangeDogBreedingAvailabilityEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogBreedingAvailabilityEndpointError, ChangeDogBreedingAvailabilityEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogBreedingAvailabilityEndpointError, ChangeDogBreedingAvailabilityEndpointVariables>({
    mutationFn: (variables: ChangeDogBreedingAvailabilityEndpointVariables) =>
      fetchChangeDogBreedingAvailabilityEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeDogBreedingPlanEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogBreedingPlanEndpointVariables = {
  body: Schemas.ChangeDogBreedingPlanCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_breeding_plan
 */
export const fetchChangeDogBreedingPlanEndpoint = (variables: ChangeDogBreedingPlanEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogBreedingPlanEndpointError, Schemas.ChangeDogBreedingPlanCommand, {}, {}, {}>({
    url: "/api/dog/change-breeding-plan",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_breeding_plan
 */
export const useChangeDogBreedingPlanEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogBreedingPlanEndpointError, ChangeDogBreedingPlanEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogBreedingPlanEndpointError, ChangeDogBreedingPlanEndpointVariables>({
    mutationFn: (variables: ChangeDogBreedingPlanEndpointVariables) =>
      fetchChangeDogBreedingPlanEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogFatherEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogFatherEndpointVariables = {
  body: Schemas.ChangeDogFatherCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_father
 */
export const fetchChangeDogFatherEndpoint = (variables: ChangeDogFatherEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogFatherEndpointError, Schemas.ChangeDogFatherCommand, {}, {}, {}>({
    url: "/api/dog/change-father",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_father
 */
export const useChangeDogFatherEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogFatherEndpointError, ChangeDogFatherEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogFatherEndpointError, ChangeDogFatherEndpointVariables>({
    mutationFn: (variables: ChangeDogFatherEndpointVariables) => fetchChangeDogFatherEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogGenderEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogGenderEndpointVariables = {
  body: Schemas.ChangeDogGenderCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_gender
 */
export const fetchChangeDogGenderEndpoint = (variables: ChangeDogGenderEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogGenderEndpointError, Schemas.ChangeDogGenderCommand, {}, {}, {}>({
    url: "/api/dog/change-gender",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_gender
 */
export const useChangeDogGenderEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogGenderEndpointError, ChangeDogGenderEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogGenderEndpointError, ChangeDogGenderEndpointVariables>({
    mutationFn: (variables: ChangeDogGenderEndpointVariables) => fetchChangeDogGenderEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogCurrentKennelEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogCurrentKennelEndpointVariables = {
  body: Schemas.ChangeDogKennelCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_current_kennel
 */
export const fetchChangeDogCurrentKennelEndpoint = (variables: ChangeDogCurrentKennelEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogCurrentKennelEndpointError, Schemas.ChangeDogKennelCommand, {}, {}, {}>({
    url: "/api/dog/change-current-kennel",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_current_kennel
 */
export const useChangeDogCurrentKennelEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogCurrentKennelEndpointError, ChangeDogCurrentKennelEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogCurrentKennelEndpointError, ChangeDogCurrentKennelEndpointVariables>({
    mutationFn: (variables: ChangeDogCurrentKennelEndpointVariables) =>
      fetchChangeDogCurrentKennelEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogBreederKennelEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogBreederKennelEndpointVariables = {
  body: Schemas.ChangeDogKennelCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_breeder_kennel
 */
export const fetchChangeDogBreederKennelEndpoint = (variables: ChangeDogBreederKennelEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogBreederKennelEndpointError, Schemas.ChangeDogKennelCommand, {}, {}, {}>({
    url: "/api/dog/change-breeder-kennel",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_breeder_kennel
 */
export const useChangeDogBreederKennelEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogBreederKennelEndpointError, ChangeDogBreederKennelEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogBreederKennelEndpointError, ChangeDogBreederKennelEndpointVariables>({
    mutationFn: (variables: ChangeDogBreederKennelEndpointVariables) =>
      fetchChangeDogBreederKennelEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogLocationEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogLocationEndpointVariables = {
  body: Schemas.ChangeDogLocationCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_location
 */
export const fetchChangeDogLocationEndpoint = (variables: ChangeDogLocationEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogLocationEndpointError, Schemas.ChangeDogLocationCommand, {}, {}, {}>({
    url: "/api/dog/change-location",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_location
 */
export const useChangeDogLocationEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogLocationEndpointError, ChangeDogLocationEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogLocationEndpointError, ChangeDogLocationEndpointVariables>({
    mutationFn: (variables: ChangeDogLocationEndpointVariables) => fetchChangeDogLocationEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogMotherEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogMotherEndpointVariables = {
  body: Schemas.ChangeDogMotherCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_mother
 */
export const fetchChangeDogMotherEndpoint = (variables: ChangeDogMotherEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogMotherEndpointError, Schemas.ChangeDogMotherCommand, {}, {}, {}>({
    url: "/api/dog/change-mother",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_mother
 */
export const useChangeDogMotherEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogMotherEndpointError, ChangeDogMotherEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogMotherEndpointError, ChangeDogMotherEndpointVariables>({
    mutationFn: (variables: ChangeDogMotherEndpointVariables) => fetchChangeDogMotherEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogNicknameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogNicknameEndpointVariables = {
  body: Schemas.ChangeDogNicknameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_nickname
 */
export const fetchChangeDogNicknameEndpoint = (variables: ChangeDogNicknameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogNicknameEndpointError, Schemas.ChangeDogNicknameCommand, {}, {}, {}>({
    url: "/api/dog/change-nickname",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_nickname
 */
export const useChangeDogNicknameEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogNicknameEndpointError, ChangeDogNicknameEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogNicknameEndpointError, ChangeDogNicknameEndpointVariables>({
    mutationFn: (variables: ChangeDogNicknameEndpointVariables) => fetchChangeDogNicknameEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogRegistryIdEndpointError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type ChangeDogRegistryIdEndpointVariables = {
  body: Schemas.ChangeDogRegistryIdCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_registry_Id
 */
export const fetchChangeDogRegistryIdEndpoint = (variables: ChangeDogRegistryIdEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.DogResponse, ChangeDogRegistryIdEndpointError, Schemas.ChangeDogRegistryIdCommand, {}, {}, {}>({
    url: "/api/dog/change-registry-Id",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_registry_Id
 */
export const useChangeDogRegistryIdEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.DogResponse, ChangeDogRegistryIdEndpointError, ChangeDogRegistryIdEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.DogResponse, ChangeDogRegistryIdEndpointError, ChangeDogRegistryIdEndpointVariables>({
    mutationFn: (variables: ChangeDogRegistryIdEndpointVariables) => fetchChangeDogRegistryIdEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogRegistryNameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogRegistryNameEndpointVariables = {
  body: Schemas.ChangeDogRegistryNameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_registry_name
 */
export const fetchChangeDogRegistryNameEndpoint = (variables: ChangeDogRegistryNameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.DogResponse, ChangeDogRegistryNameEndpointError, Schemas.ChangeDogRegistryNameCommand, {}, {}, {}>({
    url: "/api/dog/change-registry-name",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_registry_name
 */
export const useChangeDogRegistryNameEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.DogResponse, ChangeDogRegistryNameEndpointError, ChangeDogRegistryNameEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.DogResponse, ChangeDogRegistryNameEndpointError, ChangeDogRegistryNameEndpointVariables>({
    mutationFn: (variables: ChangeDogRegistryNameEndpointVariables) =>
      fetchChangeDogRegistryNameEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogShortDescriptionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogShortDescriptionEndpointVariables = {
  body: Schemas.ChangeDogShortDescriptionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_short_description
 */
export const fetchChangeDogShortDescriptionEndpoint = (variables: ChangeDogShortDescriptionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogShortDescriptionEndpointError, Schemas.ChangeDogShortDescriptionCommand, {}, {}, {}>({
    url: "/api/dog/change-short-description",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_short_description
 */
export const useChangeDogShortDescriptionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogShortDescriptionEndpointError, ChangeDogShortDescriptionEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogShortDescriptionEndpointError, ChangeDogShortDescriptionEndpointVariables>({
    mutationFn: (variables: ChangeDogShortDescriptionEndpointVariables) =>
      fetchChangeDogShortDescriptionEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeTitlesEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeTitlesEndpointVariables = {
  body: Schemas.ChangeTitlesCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_title
 */
export const fetchChangeTitlesEndpoint = (variables: ChangeTitlesEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeTitlesEndpointError, Schemas.ChangeTitlesCommand, {}, {}, {}>({
    url: "/api/dog/change-title",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_title
 */
export const useChangeTitlesEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeTitlesEndpointError, ChangeTitlesEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeTitlesEndpointError, ChangeTitlesEndpointVariables>({
    mutationFn: (variables: ChangeTitlesEndpointVariables) => fetchChangeTitlesEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogVerificationEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogVerificationEndpointVariables = {
  body: Schemas.ChangeDogVerificationCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_verify
 */
export const fetchChangeDogVerificationEndpoint = (variables: ChangeDogVerificationEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogVerificationEndpointError, Schemas.ChangeDogVerificationCommand, {}, {}, {}>({
    url: "/api/dog/verify",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_verify
 */
export const useChangeDogVerificationEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogVerificationEndpointError, ChangeDogVerificationEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogVerificationEndpointError, ChangeDogVerificationEndpointVariables>({
    mutationFn: (variables: ChangeDogVerificationEndpointVariables) =>
      fetchChangeDogVerificationEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddDogUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddDogUploadedImageEndpointVariables = {
  body: Schemas.AddDogUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_add_uploaded_image
 */
export const fetchAddDogUploadedImageEndpoint = (variables: AddDogUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddDogUploadedImageEndpointError, Schemas.AddDogUploadedImageCommand, {}, {}, {}>({
    url: "/api/dog/add-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_add_uploaded_image
 */
export const useAddDogUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddDogUploadedImageEndpointError, AddDogUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddDogUploadedImageEndpointError, AddDogUploadedImageEndpointVariables>({
    mutationFn: (variables: AddDogUploadedImageEndpointVariables) => fetchAddDogUploadedImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveDogUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDogUploadedImageEndpointVariables = {
  body: Schemas.RemoveDogUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_remove_uploaded_image
 */
export const fetchRemoveDogUploadedImageEndpoint = (variables: RemoveDogUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveDogUploadedImageEndpointError, Schemas.RemoveDogUploadedImageCommand, {}, {}, {}>({
    url: "/api/dog/remove-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_remove_uploaded_image
 */
export const useRemoveDogUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveDogUploadedImageEndpointError, RemoveDogUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveDogUploadedImageEndpointError, RemoveDogUploadedImageEndpointVariables>({
    mutationFn: (variables: RemoveDogUploadedImageEndpointVariables) =>
      fetchRemoveDogUploadedImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DogAddHealthResultsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type DogAddHealthResultsEndpointVariables = {
  body: Schemas.DogAddHealthResultsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_results_health_add
 */
export const fetchDogAddHealthResultsEndpoint = (variables: DogAddHealthResultsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, DogAddHealthResultsEndpointError, Schemas.DogAddHealthResultsCommand, {}, {}, {}>({
    url: "/api/dog/results/health/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_results_health_add
 */
export const useDogAddHealthResultsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DogAddHealthResultsEndpointError, DogAddHealthResultsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, DogAddHealthResultsEndpointError, DogAddHealthResultsEndpointVariables>({
    mutationFn: (variables: DogAddHealthResultsEndpointVariables) => fetchDogAddHealthResultsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DogRemoveHealthResultsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type DogRemoveHealthResultsEndpointVariables = {
  body: Schemas.DogRemoveHealthResultsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_results_health_remove
 */
export const fetchDogRemoveHealthResultsEndpoint = (variables: DogRemoveHealthResultsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, DogRemoveHealthResultsEndpointError, Schemas.DogRemoveHealthResultsCommand, {}, {}, {}>({
    url: "/api/dog/results/health/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_results_health_remove
 */
export const useDogRemoveHealthResultsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DogRemoveHealthResultsEndpointError, DogRemoveHealthResultsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, DogRemoveHealthResultsEndpointError, DogRemoveHealthResultsEndpointVariables>({
    mutationFn: (variables: DogRemoveHealthResultsEndpointVariables) =>
      fetchDogRemoveHealthResultsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DogVerifyHealthResultsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type DogVerifyHealthResultsEndpointVariables = {
  body: Schemas.DogVerifyHealthResultsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_results_health_verify
 */
export const fetchDogVerifyHealthResultsEndpoint = (variables: DogVerifyHealthResultsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, DogVerifyHealthResultsEndpointError, Schemas.DogVerifyHealthResultsCommand, {}, {}, {}>({
    url: "/api/dog/results/health/verify",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_results_health_verify
 */
export const useDogVerifyHealthResultsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DogVerifyHealthResultsEndpointError, DogVerifyHealthResultsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, DogVerifyHealthResultsEndpointError, DogVerifyHealthResultsEndpointVariables>({
    mutationFn: (variables: DogVerifyHealthResultsEndpointVariables) =>
      fetchDogVerifyHealthResultsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DogAddOtherResultsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type DogAddOtherResultsEndpointVariables = {
  body: Schemas.DogAddOtherResultsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_results_other_add
 */
export const fetchDogAddOtherResultsEndpoint = (variables: DogAddOtherResultsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, DogAddOtherResultsEndpointError, Schemas.DogAddOtherResultsCommand, {}, {}, {}>({
    url: "/api/dog/results/other/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_results_other_add
 */
export const useDogAddOtherResultsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DogAddOtherResultsEndpointError, DogAddOtherResultsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, DogAddOtherResultsEndpointError, DogAddOtherResultsEndpointVariables>({
    mutationFn: (variables: DogAddOtherResultsEndpointVariables) => fetchDogAddOtherResultsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DogChangeOtherResultsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type DogChangeOtherResultsEndpointVariables = {
  body: Schemas.DogChangeOtherResultsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_results_other_change
 */
export const fetchDogChangeOtherResultsEndpoint = (variables: DogChangeOtherResultsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, DogChangeOtherResultsEndpointError, Schemas.DogChangeOtherResultsCommand, {}, {}, {}>({
    url: "/api/dog/results/other/change",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_results_other_change
 */
export const useDogChangeOtherResultsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DogChangeOtherResultsEndpointError, DogChangeOtherResultsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, DogChangeOtherResultsEndpointError, DogChangeOtherResultsEndpointVariables>({
    mutationFn: (variables: DogChangeOtherResultsEndpointVariables) =>
      fetchDogChangeOtherResultsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DogDeleteOtherResultsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type DogDeleteOtherResultsEndpointVariables = {
  body: Schemas.DogDeleteOtherResultsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_results_other_delete
 */
export const fetchDogDeleteOtherResultsEndpoint = (variables: DogDeleteOtherResultsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, DogDeleteOtherResultsEndpointError, Schemas.DogDeleteOtherResultsCommand, {}, {}, {}>({
    url: "/api/dog/results/other/delete",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_results_other_delete
 */
export const useDogDeleteOtherResultsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DogDeleteOtherResultsEndpointError, DogDeleteOtherResultsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, DogDeleteOtherResultsEndpointError, DogDeleteOtherResultsEndpointVariables>({
    mutationFn: (variables: DogDeleteOtherResultsEndpointVariables) =>
      fetchDogDeleteOtherResultsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterDogEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterDogEndpointVariables = {
  body: Schemas.RegisterDogEndpointCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_register
 */
export const fetchRegisterDogEndpoint = (variables: RegisterDogEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.DogResponse, RegisterDogEndpointError, Schemas.RegisterDogEndpointCommand, {}, {}, {}>({
    url: "/api/dog/register",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_register
 */
export const useRegisterDogEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<Schemas.DogResponse, RegisterDogEndpointError, RegisterDogEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.DogResponse, RegisterDogEndpointError, RegisterDogEndpointVariables>({
    mutationFn: (variables: RegisterDogEndpointVariables) => fetchRegisterDogEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveDogEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDogEndpointVariables = {
  body: Schemas.RemoveDogCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_remove
 */
export const fetchRemoveDogEndpoint = (variables: RemoveDogEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveDogEndpointError, Schemas.RemoveDogCommand, {}, {}, {}>({
    url: "/api/dog/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_remove
 */
export const useRemoveDogEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RemoveDogEndpointError, RemoveDogEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveDogEndpointError, RemoveDogEndpointVariables>({
    mutationFn: (variables: RemoveDogEndpointVariables) => fetchRemoveDogEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterDogDewormingCureEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterDogDewormingCureEndpointVariables = {
  body: Schemas.RegisterDogDewormingCureCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_register_deworming_cure
 */
export const fetchRegisterDogDewormingCureEndpoint = (variables: RegisterDogDewormingCureEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RegisterDogDewormingCureEndpointError, Schemas.RegisterDogDewormingCureCommand, {}, {}, {}>({
    url: "/api/dog/register-deworming-cure",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_register_deworming_cure
 */
export const useRegisterDogDewormingCureEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RegisterDogDewormingCureEndpointError, RegisterDogDewormingCureEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RegisterDogDewormingCureEndpointError, RegisterDogDewormingCureEndpointVariables>({
    mutationFn: (variables: RegisterDogDewormingCureEndpointVariables) =>
      fetchRegisterDogDewormingCureEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RemoveDogDewormingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDogDewormingEndpointVariables = {
  body: Schemas.RemoveDogDewormingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_remove_deworming_cure
 */
export const fetchRemoveDogDewormingEndpoint = (variables: RemoveDogDewormingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveDogDewormingEndpointError, Schemas.RemoveDogDewormingCommand, {}, {}, {}>({
    url: "/api/dog/remove-deworming-cure",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_remove_deworming_cure
 */
export const useRemoveDogDewormingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveDogDewormingEndpointError, RemoveDogDewormingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveDogDewormingEndpointError, RemoveDogDewormingEndpointVariables>({
    mutationFn: (variables: RemoveDogDewormingEndpointVariables) => fetchRemoveDogDewormingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterDogTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterDogTrainingEndpointVariables = {
  body: Schemas.RegisterDogTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_register_training
 */
export const fetchRegisterDogTrainingEndpoint = (variables: RegisterDogTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.RegisterDogTrainingResponse, RegisterDogTrainingEndpointError, Schemas.RegisterDogTrainingCommand, {}, {}, {}>({
    url: "/api/dog/register-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_register_training
 */
export const useRegisterDogTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RegisterDogTrainingResponse,
      RegisterDogTrainingEndpointError,
      RegisterDogTrainingEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.RegisterDogTrainingResponse,
    RegisterDogTrainingEndpointError,
    RegisterDogTrainingEndpointVariables
  >({
    mutationFn: (variables: RegisterDogTrainingEndpointVariables) => fetchRegisterDogTrainingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogTrainingEndpointVariables = {
  body: Schemas.ChangeDogTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_training
 */
export const fetchChangeDogTrainingEndpoint = (variables: ChangeDogTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogTrainingEndpointError, Schemas.ChangeDogTrainingCommand, {}, {}, {}>({
    url: "/api/dog/change-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_training
 */
export const useChangeDogTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeDogTrainingEndpointError, ChangeDogTrainingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogTrainingEndpointError, ChangeDogTrainingEndpointVariables>({
    mutationFn: (variables: ChangeDogTrainingEndpointVariables) => fetchChangeDogTrainingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveDogTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDogTrainingEndpointVariables = {
  body: Schemas.RemoveDogTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_remove_training
 */
export const fetchRemoveDogTrainingEndpoint = (variables: RemoveDogTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveDogTrainingEndpointError, Schemas.RemoveDogTrainingCommand, {}, {}, {}>({
    url: "/api/dog/remove-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_remove_training
 */
export const useRemoveDogTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveDogTrainingEndpointError, RemoveDogTrainingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveDogTrainingEndpointError, RemoveDogTrainingEndpointVariables>({
    mutationFn: (variables: RemoveDogTrainingEndpointVariables) => fetchRemoveDogTrainingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterDogVaccineEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterDogVaccineEndpointVariables = {
  body: Schemas.RegisterDogVaccineCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_register_vaccine
 */
export const fetchRegisterDogVaccineEndpoint = (variables: RegisterDogVaccineEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.RegisterDogHealthRecordResponse, RegisterDogVaccineEndpointError, Schemas.RegisterDogVaccineCommand, {}, {}, {}>({
    url: "/api/dog/register-vaccine",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_register_vaccine
 */
export const useRegisterDogVaccineEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RegisterDogHealthRecordResponse,
      RegisterDogVaccineEndpointError,
      RegisterDogVaccineEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.RegisterDogHealthRecordResponse,
    RegisterDogVaccineEndpointError,
    RegisterDogVaccineEndpointVariables
  >({
    mutationFn: (variables: RegisterDogVaccineEndpointVariables) => fetchRegisterDogVaccineEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveDogVaccineEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDogVaccineEndpointVariables = {
  body: Schemas.RemoveDogVaccineCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_remove_vaccine
 */
export const fetchRemoveDogVaccineEndpoint = (variables: RemoveDogVaccineEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveDogVaccineEndpointError, Schemas.RemoveDogVaccineCommand, {}, {}, {}>({
    url: "/api/dog/remove-vaccine",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_remove_vaccine
 */
export const useRemoveDogVaccineEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RemoveDogVaccineEndpointError, RemoveDogVaccineEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveDogVaccineEndpointError, RemoveDogVaccineEndpointVariables>({
    mutationFn: (variables: RemoveDogVaccineEndpointVariables) => fetchRemoveDogVaccineEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterDogWeightEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterDogWeightEndpointVariables = {
  body: Schemas.RegisterDogWeightCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_register_weight
 */
export const fetchRegisterDogWeightEndpoint = (variables: RegisterDogWeightEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.RegisterDogHealthRecordResponse, RegisterDogWeightEndpointError, Schemas.RegisterDogWeightCommand, {}, {}, {}>({
    url: "/api/dog/register-weight",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_register_weight
 */
export const useRegisterDogWeightEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RegisterDogHealthRecordResponse,
      RegisterDogWeightEndpointError,
      RegisterDogWeightEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.RegisterDogHealthRecordResponse,
    RegisterDogWeightEndpointError,
    RegisterDogWeightEndpointVariables
  >({
    mutationFn: (variables: RegisterDogWeightEndpointVariables) => fetchRegisterDogWeightEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeDogWeightEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeDogWeightEndpointVariables = {
  body: Schemas.ChangeDogWeightCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_change_weight
 */
export const fetchChangeDogWeightEndpoint = (variables: ChangeDogWeightEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeDogWeightEndpointError, Schemas.ChangeDogWeightCommand, {}, {}, {}>({
    url: "/api/dog/change-weight",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_change_weight
 */
export const useChangeDogWeightEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeDogWeightEndpointError, ChangeDogWeightEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeDogWeightEndpointError, ChangeDogWeightEndpointVariables>({
    mutationFn: (variables: ChangeDogWeightEndpointVariables) => fetchChangeDogWeightEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveDogWeightEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDogWeightEndpointVariables = {
  body: Schemas.RemoveDogWeightCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_dog_remove_weight
 */
export const fetchRemoveDogWeightEndpoint = (variables: RemoveDogWeightEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveDogWeightEndpointError, Schemas.RemoveDogWeightCommand, {}, {}, {}>({
    url: "/api/dog/remove-weight",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_dog_remove_weight
 */
export const useRemoveDogWeightEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RemoveDogWeightEndpointError, RemoveDogWeightEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveDogWeightEndpointError, RemoveDogWeightEndpointVariables>({
    mutationFn: (variables: RemoveDogWeightEndpointVariables) => fetchRemoveDogWeightEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPrivateBreederLittersDetailsEndpointPathParams = {
  /**
   * @format uuid
   */
  breederId: string;
};

export type GetPrivateBreederLittersDetailsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateBreederLittersDetailsEndpointResponse = Schemas.LitterPrivateDetailsView[];

export type GetPrivateBreederLittersDetailsEndpointVariables = {
  pathParams: GetPrivateBreederLittersDetailsEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederId_private_litters
 */
export const fetchGetPrivateBreederLittersDetailsEndpoint = (
  variables: GetPrivateBreederLittersDetailsEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<
    GetPrivateBreederLittersDetailsEndpointResponse,
    GetPrivateBreederLittersDetailsEndpointError,
    undefined,
    {},
    {},
    GetPrivateBreederLittersDetailsEndpointPathParams
  >({
    url: "/api/breeder/{breederId}/private/litters",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederId_private_litters
 */
export const useGetPrivateBreederLittersDetailsEndpoint = <TData = GetPrivateBreederLittersDetailsEndpointResponse>(
  variables: GetPrivateBreederLittersDetailsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPrivateBreederLittersDetailsEndpointResponse, GetPrivateBreederLittersDetailsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetPrivateBreederLittersDetailsEndpointResponse, GetPrivateBreederLittersDetailsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederId}/private/litters",
      operationId: "getPrivateBreederLittersDetailsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateBreederLittersDetailsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateLitterDetailsEndpointPathParams = {
  /**
   * @format uuid
   */
  litterId: string;
};

export type GetPrivateLitterDetailsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateLitterDetailsEndpointVariables = {
  pathParams: GetPrivateLitterDetailsEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_litter_litterId_private
 */
export const fetchGetPrivateLitterDetailsEndpoint = (variables: GetPrivateLitterDetailsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.LitterPrivateDetailsView,
    GetPrivateLitterDetailsEndpointError,
    undefined,
    {},
    {},
    GetPrivateLitterDetailsEndpointPathParams
  >({
    url: "/api/litter/{litterId}/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_litter_litterId_private
 */
export const useGetPrivateLitterDetailsEndpoint = <TData = Schemas.LitterPrivateDetailsView>(
  variables: GetPrivateLitterDetailsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.LitterPrivateDetailsView, GetPrivateLitterDetailsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.LitterPrivateDetailsView, GetPrivateLitterDetailsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/litter/{litterId}/private",
      operationId: "getPrivateLitterDetailsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateLitterDetailsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPublicLitterBySlugEndpointPathParams = {
  breederSlug: string;
  litterSlug: string;
};

export type GetPublicLitterBySlugEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicLitterBySlugEndpointVariables = {
  pathParams: GetPublicLitterBySlugEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederSlug_litters_litterSlug_public
 */
export const fetchGetPublicLitterBySlugEndpoint = (variables: GetPublicLitterBySlugEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.LitterPublicDetailsView,
    GetPublicLitterBySlugEndpointError,
    undefined,
    {},
    {},
    GetPublicLitterBySlugEndpointPathParams
  >({
    url: "/api/breeder/{breederSlug}/litters/{litterSlug}/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederSlug_litters_litterSlug_public
 */
export const useGetPublicLitterBySlugEndpoint = <TData = Schemas.LitterPublicDetailsView>(
  variables: GetPublicLitterBySlugEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.LitterPublicDetailsView, GetPublicLitterBySlugEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.LitterPublicDetailsView, GetPublicLitterBySlugEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederSlug}/litters/{litterSlug}/public",
      operationId: "getPublicLitterBySlugEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPublicLitterBySlugEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPublicLitterByBreederSlugEndpointPathParams = {
  breederSlug: string;
};

export type GetPublicLitterByBreederSlugEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPublicLitterByBreederSlugEndpointResponse = Schemas.LitterPublicDetailsView[];

export type GetPublicLitterByBreederSlugEndpointVariables = {
  pathParams: GetPublicLitterByBreederSlugEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_breeder_breederSlug_litters_public
 */
export const fetchGetPublicLitterByBreederSlugEndpoint = (variables: GetPublicLitterByBreederSlugEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    GetPublicLitterByBreederSlugEndpointResponse,
    GetPublicLitterByBreederSlugEndpointError,
    undefined,
    {},
    {},
    GetPublicLitterByBreederSlugEndpointPathParams
  >({
    url: "/api/breeder/{breederSlug}/litters/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_breeder_breederSlug_litters_public
 */
export const useGetPublicLitterByBreederSlugEndpoint = <TData = GetPublicLitterByBreederSlugEndpointResponse>(
  variables: GetPublicLitterByBreederSlugEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPublicLitterByBreederSlugEndpointResponse, GetPublicLitterByBreederSlugEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetPublicLitterByBreederSlugEndpointResponse, GetPublicLitterByBreederSlugEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/breeder/{breederSlug}/litters/public",
      operationId: "getPublicLitterByBreederSlugEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPublicLitterByBreederSlugEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllLittersEndpointQueryParams = {
  /**
   * @format uuid
   */
  cursor?: string;
  /**
   * @format int32
   */
  limit?: number;
  breeds?: string;
};

export type AllLittersEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllLittersEndpointResponse = Schemas.LitterPublicDetailsView[];

export type AllLittersEndpointVariables = {
  queryParams?: AllLittersEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_litters_public
 */
export const fetchAllLittersEndpoint = (variables: AllLittersEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllLittersEndpointResponse, AllLittersEndpointError, undefined, {}, AllLittersEndpointQueryParams, {}>({
    url: "/api/litters/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_litters_public
 */
export const useAllLittersEndpoint = <TData = AllLittersEndpointResponse>(
  variables: AllLittersEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllLittersEndpointResponse, AllLittersEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllLittersEndpointResponse, AllLittersEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/litters/public",
      operationId: "allLittersEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllLittersEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllLittersRandomizedEndpointQueryParams = {
  /**
   * @format int32
   */
  page?: number;
  /**
   * @format int32
   */
  limit?: number;
  breeds?: string;
};

export type AllLittersRandomizedEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllLittersRandomizedEndpointResponse = Schemas.LitterPublicDetailsView[];

export type AllLittersRandomizedEndpointVariables = {
  queryParams?: AllLittersRandomizedEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_litters_randomized_public
 */
export const fetchAllLittersRandomizedEndpoint = (variables: AllLittersRandomizedEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    AllLittersRandomizedEndpointResponse,
    AllLittersRandomizedEndpointError,
    undefined,
    {},
    AllLittersRandomizedEndpointQueryParams,
    {}
  >({
    url: "/api/litters/randomized/public",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_litters_randomized_public
 */
export const useAllLittersRandomizedEndpoint = <TData = AllLittersRandomizedEndpointResponse>(
  variables: AllLittersRandomizedEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllLittersRandomizedEndpointResponse, AllLittersRandomizedEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllLittersRandomizedEndpointResponse, AllLittersRandomizedEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/litters/randomized/public",
      operationId: "allLittersRandomizedEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllLittersRandomizedEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ChangeLitterBreedingDescriptionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterBreedingDescriptionEndpointVariables = {
  body: Schemas.ChangeLitterBreedingDescriptionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_breeding_description
 */
export const fetchChangeLitterBreedingDescriptionEndpoint = (
  variables: ChangeLitterBreedingDescriptionEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ChangeLitterBreedingDescriptionEndpointError, Schemas.ChangeLitterBreedingDescriptionCommand, {}, {}, {}>({
    url: "/api/litter/change-breeding-description",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_breeding_description
 */
export const useChangeLitterBreedingDescriptionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangeLitterBreedingDescriptionEndpointError,
      ChangeLitterBreedingDescriptionEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterBreedingDescriptionEndpointError, ChangeLitterBreedingDescriptionEndpointVariables>({
    mutationFn: (variables: ChangeLitterBreedingDescriptionEndpointVariables) =>
      fetchChangeLitterBreedingDescriptionEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterDeliveryReadyAtEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterDeliveryReadyAtEndpointVariables = {
  body: Schemas.ChangeLitterDeliveryReadyAtCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_delivery_ready_at
 */
export const fetchChangeLitterDeliveryReadyAtEndpoint = (variables: ChangeLitterDeliveryReadyAtEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterDeliveryReadyAtEndpointError, Schemas.ChangeLitterDeliveryReadyAtCommand, {}, {}, {}>({
    url: "/api/litter/change-delivery-ready-at",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_delivery_ready_at
 */
export const useChangeLitterDeliveryReadyAtEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterDeliveryReadyAtEndpointError, ChangeLitterDeliveryReadyAtEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterDeliveryReadyAtEndpointError, ChangeLitterDeliveryReadyAtEndpointVariables>({
    mutationFn: (variables: ChangeLitterDeliveryReadyAtEndpointVariables) =>
      fetchChangeLitterDeliveryReadyAtEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterFamilyCombinationDescriptionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterFamilyCombinationDescriptionEndpointVariables = {
  body: Schemas.ChangeLitterFamilyCombinationDescriptionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_family_combination_description
 */
export const fetchChangeLitterFamilyCombinationDescriptionEndpoint = (
  variables: ChangeLitterFamilyCombinationDescriptionEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<
    undefined,
    ChangeLitterFamilyCombinationDescriptionEndpointError,
    Schemas.ChangeLitterFamilyCombinationDescriptionCommand,
    {},
    {},
    {}
  >({
    url: "/api/litter/change-family-combination-description",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_family_combination_description
 */
export const useChangeLitterFamilyCombinationDescriptionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangeLitterFamilyCombinationDescriptionEndpointError,
      ChangeLitterFamilyCombinationDescriptionEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ChangeLitterFamilyCombinationDescriptionEndpointError,
    ChangeLitterFamilyCombinationDescriptionEndpointVariables
  >({
    mutationFn: (variables: ChangeLitterFamilyCombinationDescriptionEndpointVariables) =>
      fetchChangeLitterFamilyCombinationDescriptionEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterFatherEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterFatherEndpointVariables = {
  body: Schemas.ChangeLitterFatherCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_farther
 */
export const fetchChangeLitterFatherEndpoint = (variables: ChangeLitterFatherEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterFatherEndpointError, Schemas.ChangeLitterFatherCommand, {}, {}, {}>({
    url: "/api/litter/change-farther",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_farther
 */
export const useChangeLitterFatherEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterFatherEndpointError, ChangeLitterFatherEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterFatherEndpointError, ChangeLitterFatherEndpointVariables>({
    mutationFn: (variables: ChangeLitterFatherEndpointVariables) => fetchChangeLitterFatherEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterLocationEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterLocationEndpointVariables = {
  body: Schemas.ChangeLitterLocationCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_Location
 */
export const fetchChangeLitterLocationEndpoint = (variables: ChangeLitterLocationEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterLocationEndpointError, Schemas.ChangeLitterLocationCommand, {}, {}, {}>({
    url: "/api/litter/change-Location",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_Location
 */
export const useChangeLitterLocationEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterLocationEndpointError, ChangeLitterLocationEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterLocationEndpointError, ChangeLitterLocationEndpointVariables>({
    mutationFn: (variables: ChangeLitterLocationEndpointVariables) =>
      fetchChangeLitterLocationEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterMeetsClubBreederRequirementsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterMeetsClubBreederRequirementsEndpointVariables = {
  body: Schemas.ChangeLitterMeetsClubBreederRequirementsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_meets_club_breeder_requirements
 */
export const fetchChangeLitterMeetsClubBreederRequirementsEndpoint = (
  variables: ChangeLitterMeetsClubBreederRequirementsEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<
    undefined,
    ChangeLitterMeetsClubBreederRequirementsEndpointError,
    Schemas.ChangeLitterMeetsClubBreederRequirementsCommand,
    {},
    {},
    {}
  >({
    url: "/api/litter/change-meets-club-breeder-requirements",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_meets_club_breeder_requirements
 */
export const useChangeLitterMeetsClubBreederRequirementsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangeLitterMeetsClubBreederRequirementsEndpointError,
      ChangeLitterMeetsClubBreederRequirementsEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ChangeLitterMeetsClubBreederRequirementsEndpointError,
    ChangeLitterMeetsClubBreederRequirementsEndpointVariables
  >({
    mutationFn: (variables: ChangeLitterMeetsClubBreederRequirementsEndpointVariables) =>
      fetchChangeLitterMeetsClubBreederRequirementsEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterMeetsNkkBreederRequirementsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterMeetsNkkBreederRequirementsEndpointVariables = {
  body: Schemas.ChangeLitterMeetsNkkBreederRequirementsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_meets_nkk_breeder_requirements
 */
export const fetchChangeLitterMeetsNkkBreederRequirementsEndpoint = (
  variables: ChangeLitterMeetsNkkBreederRequirementsEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<
    undefined,
    ChangeLitterMeetsNkkBreederRequirementsEndpointError,
    Schemas.ChangeLitterMeetsNkkBreederRequirementsCommand,
    {},
    {},
    {}
  >({
    url: "/api/litter/change-meets-nkk-breeder-requirements",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_meets_nkk_breeder_requirements
 */
export const useChangeLitterMeetsNkkBreederRequirementsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangeLitterMeetsNkkBreederRequirementsEndpointError,
      ChangeLitterMeetsNkkBreederRequirementsEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ChangeLitterMeetsNkkBreederRequirementsEndpointError,
    ChangeLitterMeetsNkkBreederRequirementsEndpointVariables
  >({
    mutationFn: (variables: ChangeLitterMeetsNkkBreederRequirementsEndpointVariables) =>
      fetchChangeLitterMeetsNkkBreederRequirementsEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterMotherEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterMotherEndpointVariables = {
  body: Schemas.ChangeLitterMotherCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_mother
 */
export const fetchChangeLitterMotherEndpoint = (variables: ChangeLitterMotherEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterMotherEndpointError, Schemas.ChangeLitterMotherCommand, {}, {}, {}>({
    url: "/api/litter/change-mother",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_mother
 */
export const useChangeLitterMotherEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterMotherEndpointError, ChangeLitterMotherEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterMotherEndpointError, ChangeLitterMotherEndpointVariables>({
    mutationFn: (variables: ChangeLitterMotherEndpointVariables) => fetchChangeLitterMotherEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterNameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterNameEndpointVariables = {
  body: Schemas.ChangeLitterNameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_name
 */
export const fetchChangeLitterNameEndpoint = (variables: ChangeLitterNameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.LitterResponse, ChangeLitterNameEndpointError, Schemas.ChangeLitterNameCommand, {}, {}, {}>({
    url: "/api/litter/change-name",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_name
 */
export const useChangeLitterNameEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.LitterResponse, ChangeLitterNameEndpointError, ChangeLitterNameEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.LitterResponse, ChangeLitterNameEndpointError, ChangeLitterNameEndpointVariables>({
    mutationFn: (variables: ChangeLitterNameEndpointVariables) => fetchChangeLitterNameEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterPriceEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPriceEndpointVariables = {
  body: Schemas.ChangeLitterPriceCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_price
 */
export const fetchChangeLitterPriceEndpoint = (variables: ChangeLitterPriceEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPriceEndpointError, Schemas.ChangeLitterPriceCommand, {}, {}, {}>({
    url: "/api/litter/change-price",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_price
 */
export const useChangeLitterPriceEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPriceEndpointError, ChangeLitterPriceEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPriceEndpointError, ChangeLitterPriceEndpointVariables>({
    mutationFn: (variables: ChangeLitterPriceEndpointVariables) => fetchChangeLitterPriceEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterAvailabilityEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterAvailabilityEndpointVariables = {
  body: Schemas.ChangeLitterAvailabilityCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_availability
 */
export const fetchChangeLitterAvailabilityEndpoint = (variables: ChangeLitterAvailabilityEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterAvailabilityEndpointError, Schemas.ChangeLitterAvailabilityCommand, {}, {}, {}>({
    url: "/api/litter/change-availability",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_availability
 */
export const useChangeLitterAvailabilityEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterAvailabilityEndpointError, ChangeLitterAvailabilityEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterAvailabilityEndpointError, ChangeLitterAvailabilityEndpointVariables>({
    mutationFn: (variables: ChangeLitterAvailabilityEndpointVariables) =>
      fetchChangeLitterAvailabilityEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterTermEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterTermEndpointVariables = {
  body: Schemas.ChangeLitterTermCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_term
 */
export const fetchChangeLitterTermEndpoint = (variables: ChangeLitterTermEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterTermEndpointError, Schemas.ChangeLitterTermCommand, {}, {}, {}>({
    url: "/api/litter/change-term",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_term
 */
export const useChangeLitterTermEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeLitterTermEndpointError, ChangeLitterTermEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterTermEndpointError, ChangeLitterTermEndpointVariables>({
    mutationFn: (variables: ChangeLitterTermEndpointVariables) => fetchChangeLitterTermEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddLitterUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddLitterUploadedImageEndpointVariables = {
  body: Schemas.AddLitterUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_add_uploaded_image
 */
export const fetchAddLitterUploadedImageEndpoint = (variables: AddLitterUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddLitterUploadedImageEndpointError, Schemas.AddLitterUploadedImageCommand, {}, {}, {}>({
    url: "/api/litter/add-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_add_uploaded_image
 */
export const useAddLitterUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddLitterUploadedImageEndpointError, AddLitterUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddLitterUploadedImageEndpointError, AddLitterUploadedImageEndpointVariables>({
    mutationFn: (variables: AddLitterUploadedImageEndpointVariables) =>
      fetchAddLitterUploadedImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveLitterUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterUploadedImageEndpointVariables = {
  body: Schemas.RemoveLitterUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_remove_uploaded_image
 */
export const fetchRemoveLitterUploadedImageEndpoint = (variables: RemoveLitterUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterUploadedImageEndpointError, Schemas.RemoveLitterUploadedImageCommand, {}, {}, {}>({
    url: "/api/litter/remove-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_remove_uploaded_image
 */
export const useRemoveLitterUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterUploadedImageEndpointError, RemoveLitterUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterUploadedImageEndpointError, RemoveLitterUploadedImageEndpointVariables>({
    mutationFn: (variables: RemoveLitterUploadedImageEndpointVariables) =>
      fetchRemoveLitterUploadedImageEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type AddLitterPuppyEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddLitterPuppyEndpointVariables = {
  body: Schemas.AddLitterPuppyCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_add
 */
export const fetchAddLitterPuppyEndpoint = (variables: AddLitterPuppyEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.AddLitterPuppyResponse, AddLitterPuppyEndpointError, Schemas.AddLitterPuppyCommand, {}, {}, {}>({
    url: "/api/litter/puppy/add",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_add
 */
export const useAddLitterPuppyEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.AddLitterPuppyResponse, AddLitterPuppyEndpointError, AddLitterPuppyEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.AddLitterPuppyResponse, AddLitterPuppyEndpointError, AddLitterPuppyEndpointVariables>({
    mutationFn: (variables: AddLitterPuppyEndpointVariables) => fetchAddLitterPuppyEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveLitterPuppyEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterPuppyEndpointVariables = {
  body: Schemas.RemoveLitterPuppyCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_remove
 */
export const fetchRemoveLitterPuppyEndpoint = (variables: RemoveLitterPuppyEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterPuppyEndpointError, Schemas.RemoveLitterPuppyCommand, {}, {}, {}>({
    url: "/api/litter/puppy/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_remove
 */
export const useRemoveLitterPuppyEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterPuppyEndpointError, RemoveLitterPuppyEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterPuppyEndpointError, RemoveLitterPuppyEndpointVariables>({
    mutationFn: (variables: RemoveLitterPuppyEndpointVariables) => fetchRemoveLitterPuppyEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterPuppyAvailableEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyAvailableEndpointVariables = {
  body: Schemas.ChangeLitterPuppyAvailableCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_available
 */
export const fetchChangeLitterPuppyAvailableEndpoint = (variables: ChangeLitterPuppyAvailableEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyAvailableEndpointError, Schemas.ChangeLitterPuppyAvailableCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-available",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_available
 */
export const useChangeLitterPuppyAvailableEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyAvailableEndpointError, ChangeLitterPuppyAvailableEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyAvailableEndpointError, ChangeLitterPuppyAvailableEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyAvailableEndpointVariables) =>
      fetchChangeLitterPuppyAvailableEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterPuppyBornAtEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyBornAtEndpointVariables = {
  body: Schemas.ChangeLitterPuppyBornAtCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_born_at
 */
export const fetchChangeLitterPuppyBornAtEndpoint = (variables: ChangeLitterPuppyBornAtEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyBornAtEndpointError, Schemas.ChangeLitterPuppyBornAtCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-born-at",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_born_at
 */
export const useChangeLitterPuppyBornAtEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyBornAtEndpointError, ChangeLitterPuppyBornAtEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyBornAtEndpointError, ChangeLitterPuppyBornAtEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyBornAtEndpointVariables) =>
      fetchChangeLitterPuppyBornAtEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterPuppyColorEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyColorEndpointVariables = {
  body: Schemas.ChangeLitterPuppyColorCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_color
 */
export const fetchChangeLitterPuppyColorEndpoint = (variables: ChangeLitterPuppyColorEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyColorEndpointError, Schemas.ChangeLitterPuppyColorCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-color",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_color
 */
export const useChangeLitterPuppyColorEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyColorEndpointError, ChangeLitterPuppyColorEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyColorEndpointError, ChangeLitterPuppyColorEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyColorEndpointVariables) =>
      fetchChangeLitterPuppyColorEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterPuppyDescriptionEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyDescriptionEndpointVariables = {
  body: Schemas.ChangeLitterPuppyDescriptionCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_description
 */
export const fetchChangeLitterPuppyDescriptionEndpoint = (variables: ChangeLitterPuppyDescriptionEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyDescriptionEndpointError, Schemas.ChangeLitterPuppyDescriptionCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-description",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_description
 */
export const useChangeLitterPuppyDescriptionEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyDescriptionEndpointError, ChangeLitterPuppyDescriptionEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyDescriptionEndpointError, ChangeLitterPuppyDescriptionEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyDescriptionEndpointVariables) =>
      fetchChangeLitterPuppyDescriptionEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterPuppyGenderEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyGenderEndpointVariables = {
  body: Schemas.ChangeLitterPuppyGenderCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_gender
 */
export const fetchChangeLitterPuppyGenderEndpoint = (variables: ChangeLitterPuppyGenderEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyGenderEndpointError, Schemas.ChangeLitterPuppyGenderCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-gender",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_gender
 */
export const useChangeLitterPuppyGenderEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyGenderEndpointError, ChangeLitterPuppyGenderEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyGenderEndpointError, ChangeLitterPuppyGenderEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyGenderEndpointVariables) =>
      fetchChangeLitterPuppyGenderEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterPuppyKennelClubDetailsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyKennelClubDetailsEndpointVariables = {
  body: Schemas.ChangeLitterPuppyKennelClubDetailsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_registry_details
 */
export const fetchChangeLitterPuppyKennelClubDetailsEndpoint = (
  variables: ChangeLitterPuppyKennelClubDetailsEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, ChangeLitterPuppyKennelClubDetailsEndpointError, Schemas.ChangeLitterPuppyKennelClubDetailsCommand, {}, {}, {}>(
    {
      url: "/api/litter/puppy/change-registry-details",
      method: "post",
      ...variables,
      signal,
    },
  );

/**
 * POST_api_litter_puppy_change_registry_details
 */
export const useChangeLitterPuppyKennelClubDetailsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangeLitterPuppyKennelClubDetailsEndpointError,
      ChangeLitterPuppyKennelClubDetailsEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    ChangeLitterPuppyKennelClubDetailsEndpointError,
    ChangeLitterPuppyKennelClubDetailsEndpointVariables
  >({
    mutationFn: (variables: ChangeLitterPuppyKennelClubDetailsEndpointVariables) =>
      fetchChangeLitterPuppyKennelClubDetailsEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterPuppyNameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyNameEndpointVariables = {
  body: Schemas.ChangeLitterPuppyNameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_name
 */
export const fetchChangeLitterPuppyNameEndpoint = (variables: ChangeLitterPuppyNameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyNameEndpointError, Schemas.ChangeLitterPuppyNameCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-name",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_name
 */
export const useChangeLitterPuppyNameEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyNameEndpointError, ChangeLitterPuppyNameEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyNameEndpointError, ChangeLitterPuppyNameEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyNameEndpointVariables) =>
      fetchChangeLitterPuppyNameEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AddLitterPuppyImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddLitterPuppyImageEndpointVariables = {
  body: Schemas.AddLitterPuppyImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_add_uploaded_image
 */
export const fetchAddLitterPuppyImageEndpoint = (variables: AddLitterPuppyImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddLitterPuppyImageEndpointError, Schemas.AddLitterPuppyImageCommand, {}, {}, {}>({
    url: "/api/litter/puppy/add-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_add_uploaded_image
 */
export const useAddLitterPuppyImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddLitterPuppyImageEndpointError, AddLitterPuppyImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddLitterPuppyImageEndpointError, AddLitterPuppyImageEndpointVariables>({
    mutationFn: (variables: AddLitterPuppyImageEndpointVariables) => fetchAddLitterPuppyImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveLitterPuppyImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterPuppyImageEndpointVariables = {
  body: Schemas.RemoveLitterPuppyImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_remove_uploaded_image
 */
export const fetchRemoveLitterPuppyImageEndpoint = (variables: RemoveLitterPuppyImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterPuppyImageEndpointError, Schemas.RemoveLitterPuppyImageCommand, {}, {}, {}>({
    url: "/api/litter/puppy/remove-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_remove_uploaded_image
 */
export const useRemoveLitterPuppyImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterPuppyImageEndpointError, RemoveLitterPuppyImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterPuppyImageEndpointError, RemoveLitterPuppyImageEndpointVariables>({
    mutationFn: (variables: RemoveLitterPuppyImageEndpointVariables) =>
      fetchRemoveLitterPuppyImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterLitterPuppyDewormingCureEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterLitterPuppyDewormingCureEndpointVariables = {
  body: Schemas.RegisterLitterPuppyDewormingCureCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_register_deworming_cure
 */
export const fetchRegisterLitterPuppyDewormingCureEndpoint = (
  variables: RegisterLitterPuppyDewormingCureEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<undefined, RegisterLitterPuppyDewormingCureEndpointError, Schemas.RegisterLitterPuppyDewormingCureCommand, {}, {}, {}>({
    url: "/api/litter/puppy/register-deworming-cure",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_register_deworming_cure
 */
export const useRegisterLitterPuppyDewormingCureEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RegisterLitterPuppyDewormingCureEndpointError,
      RegisterLitterPuppyDewormingCureEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    undefined,
    RegisterLitterPuppyDewormingCureEndpointError,
    RegisterLitterPuppyDewormingCureEndpointVariables
  >({
    mutationFn: (variables: RegisterLitterPuppyDewormingCureEndpointVariables) =>
      fetchRegisterLitterPuppyDewormingCureEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RemoveLitterPuppyDewormingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterPuppyDewormingEndpointVariables = {
  body: Schemas.RemoveLitterPuppyDewormingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_remove_deworming_cure
 */
export const fetchRemoveLitterPuppyDewormingEndpoint = (variables: RemoveLitterPuppyDewormingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterPuppyDewormingEndpointError, Schemas.RemoveLitterPuppyDewormingCommand, {}, {}, {}>({
    url: "/api/litter/puppy/remove-deworming-cure",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_remove_deworming_cure
 */
export const useRemoveLitterPuppyDewormingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterPuppyDewormingEndpointError, RemoveLitterPuppyDewormingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterPuppyDewormingEndpointError, RemoveLitterPuppyDewormingEndpointVariables>({
    mutationFn: (variables: RemoveLitterPuppyDewormingEndpointVariables) =>
      fetchRemoveLitterPuppyDewormingEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RegisterLitterPuppyTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterLitterPuppyTrainingEndpointVariables = {
  body: Schemas.RegisterLitterPuppyTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_register_training
 */
export const fetchRegisterLitterPuppyTrainingEndpoint = (variables: RegisterLitterPuppyTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.RegisterTrainingResponse,
    RegisterLitterPuppyTrainingEndpointError,
    Schemas.RegisterLitterPuppyTrainingCommand,
    {},
    {},
    {}
  >({
    url: "/api/litter/puppy/register-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_register_training
 */
export const useRegisterLitterPuppyTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RegisterTrainingResponse,
      RegisterLitterPuppyTrainingEndpointError,
      RegisterLitterPuppyTrainingEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.RegisterTrainingResponse,
    RegisterLitterPuppyTrainingEndpointError,
    RegisterLitterPuppyTrainingEndpointVariables
  >({
    mutationFn: (variables: RegisterLitterPuppyTrainingEndpointVariables) =>
      fetchRegisterLitterPuppyTrainingEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterPuppyTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyTrainingEndpointVariables = {
  body: Schemas.ChangeLitterPuppyTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_training
 */
export const fetchChangeLitterPuppyTrainingEndpoint = (variables: ChangeLitterPuppyTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyTrainingEndpointError, Schemas.ChangeLitterPuppyTrainingCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_training
 */
export const useChangeLitterPuppyTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyTrainingEndpointError, ChangeLitterPuppyTrainingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyTrainingEndpointError, ChangeLitterPuppyTrainingEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyTrainingEndpointVariables) =>
      fetchChangeLitterPuppyTrainingEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RemoveLitterPuppyTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterPuppyTrainingEndpointVariables = {
  body: Schemas.RemoveLitterPuppyTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_remove_training
 */
export const fetchRemoveLitterPuppyTrainingEndpoint = (variables: RemoveLitterPuppyTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterPuppyTrainingEndpointError, Schemas.RemoveLitterPuppyTrainingCommand, {}, {}, {}>({
    url: "/api/litter/puppy/remove-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_remove_training
 */
export const useRemoveLitterPuppyTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterPuppyTrainingEndpointError, RemoveLitterPuppyTrainingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterPuppyTrainingEndpointError, RemoveLitterPuppyTrainingEndpointVariables>({
    mutationFn: (variables: RemoveLitterPuppyTrainingEndpointVariables) =>
      fetchRemoveLitterPuppyTrainingEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RegisterLitterTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterLitterTrainingEndpointVariables = {
  body: Schemas.RegisterLitterTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_register_training
 */
export const fetchRegisterLitterTrainingEndpoint = (variables: RegisterLitterTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.RegisterTrainingResponse, RegisterLitterTrainingEndpointError, Schemas.RegisterLitterTrainingCommand, {}, {}, {}>({
    url: "/api/litter/register-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_register_training
 */
export const useRegisterLitterTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RegisterTrainingResponse,
      RegisterLitterTrainingEndpointError,
      RegisterLitterTrainingEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.RegisterTrainingResponse,
    RegisterLitterTrainingEndpointError,
    RegisterLitterTrainingEndpointVariables
  >({
    mutationFn: (variables: RegisterLitterTrainingEndpointVariables) =>
      fetchRegisterLitterTrainingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeLitterTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterTrainingEndpointVariables = {
  body: Schemas.ChangeLitterTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_change_training
 */
export const fetchChangeLitterTrainingEndpoint = (variables: ChangeLitterTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterTrainingEndpointError, Schemas.ChangeLitterTrainingCommand, {}, {}, {}>({
    url: "/api/litter/change-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_change_training
 */
export const useChangeLitterTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterTrainingEndpointError, ChangeLitterTrainingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterTrainingEndpointError, ChangeLitterTrainingEndpointVariables>({
    mutationFn: (variables: ChangeLitterTrainingEndpointVariables) =>
      fetchChangeLitterTrainingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveLitterTrainingEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterTrainingEndpointVariables = {
  body: Schemas.RemoveLitterTrainingCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_remove_training
 */
export const fetchRemoveLitterTrainingEndpoint = (variables: RemoveLitterTrainingEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterTrainingEndpointError, Schemas.RemoveLitterTrainingCommand, {}, {}, {}>({
    url: "/api/litter/remove-training",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_remove_training
 */
export const useRemoveLitterTrainingEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterTrainingEndpointError, RemoveLitterTrainingEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterTrainingEndpointError, RemoveLitterTrainingEndpointVariables>({
    mutationFn: (variables: RemoveLitterTrainingEndpointVariables) =>
      fetchRemoveLitterTrainingEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterLitterPuppyVaccineEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterLitterPuppyVaccineEndpointVariables = {
  body: Schemas.RegisterLitterPuppyVaccineCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_register_vaccine
 */
export const fetchRegisterLitterPuppyVaccineEndpoint = (variables: RegisterLitterPuppyVaccineEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RegisterLitterPuppyVaccineEndpointError, Schemas.RegisterLitterPuppyVaccineCommand, {}, {}, {}>({
    url: "/api/litter/puppy/register-vaccine",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_register_vaccine
 */
export const useRegisterLitterPuppyVaccineEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RegisterLitterPuppyVaccineEndpointError, RegisterLitterPuppyVaccineEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RegisterLitterPuppyVaccineEndpointError, RegisterLitterPuppyVaccineEndpointVariables>({
    mutationFn: (variables: RegisterLitterPuppyVaccineEndpointVariables) =>
      fetchRegisterLitterPuppyVaccineEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RemoveLitterPuppyVaccineEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterPuppyVaccineEndpointVariables = {
  body: Schemas.RemoveLitterPuppyVaccineCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_remove_vaccine
 */
export const fetchRemoveLitterPuppyVaccineEndpoint = (variables: RemoveLitterPuppyVaccineEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterPuppyVaccineEndpointError, Schemas.RemoveLitterPuppyVaccineCommand, {}, {}, {}>({
    url: "/api/litter/puppy/remove-vaccine",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_remove_vaccine
 */
export const useRemoveLitterPuppyVaccineEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterPuppyVaccineEndpointError, RemoveLitterPuppyVaccineEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterPuppyVaccineEndpointError, RemoveLitterPuppyVaccineEndpointVariables>({
    mutationFn: (variables: RemoveLitterPuppyVaccineEndpointVariables) =>
      fetchRemoveLitterPuppyVaccineEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RegisterLitterPuppyWeightEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterLitterPuppyWeightEndpointVariables = {
  body: Schemas.RegisterLitterPuppyWeightCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_register_weight
 */
export const fetchRegisterLitterPuppyWeightEndpoint = (variables: RegisterLitterPuppyWeightEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.RegisterLitterPuppyWeightResponse,
    RegisterLitterPuppyWeightEndpointError,
    Schemas.RegisterLitterPuppyWeightCommand,
    {},
    {},
    {}
  >({
    url: "/api/litter/puppy/register-weight",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_register_weight
 */
export const useRegisterLitterPuppyWeightEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RegisterLitterPuppyWeightResponse,
      RegisterLitterPuppyWeightEndpointError,
      RegisterLitterPuppyWeightEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.RegisterLitterPuppyWeightResponse,
    RegisterLitterPuppyWeightEndpointError,
    RegisterLitterPuppyWeightEndpointVariables
  >({
    mutationFn: (variables: RegisterLitterPuppyWeightEndpointVariables) =>
      fetchRegisterLitterPuppyWeightEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeLitterPuppyWeightEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeLitterPuppyWeightEndpointVariables = {
  body: Schemas.ChangeLitterPuppyWeightCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_change_weight
 */
export const fetchChangeLitterPuppyWeightEndpoint = (variables: ChangeLitterPuppyWeightEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeLitterPuppyWeightEndpointError, Schemas.ChangeLitterPuppyWeightCommand, {}, {}, {}>({
    url: "/api/litter/puppy/change-weight",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_change_weight
 */
export const useChangeLitterPuppyWeightEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeLitterPuppyWeightEndpointError, ChangeLitterPuppyWeightEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeLitterPuppyWeightEndpointError, ChangeLitterPuppyWeightEndpointVariables>({
    mutationFn: (variables: ChangeLitterPuppyWeightEndpointVariables) =>
      fetchChangeLitterPuppyWeightEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveLitterPuppyWeightEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterPuppyWeightEndpointVariables = {
  body: Schemas.RemoveLitterPuppyWeightCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_puppy_remove_weight
 */
export const fetchRemoveLitterPuppyWeightEndpoint = (variables: RemoveLitterPuppyWeightEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterPuppyWeightEndpointError, Schemas.RemoveLitterPuppyWeightCommand, {}, {}, {}>({
    url: "/api/litter/puppy/remove-weight",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_puppy_remove_weight
 */
export const useRemoveLitterPuppyWeightEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveLitterPuppyWeightEndpointError, RemoveLitterPuppyWeightEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterPuppyWeightEndpointError, RemoveLitterPuppyWeightEndpointVariables>({
    mutationFn: (variables: RemoveLitterPuppyWeightEndpointVariables) =>
      fetchRemoveLitterPuppyWeightEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterLitterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RegisterLitterEndpointVariables = {
  body: Schemas.RegisterLitterEndpointCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_register
 */
export const fetchRegisterLitterEndpoint = (variables: RegisterLitterEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.LitterResponse, RegisterLitterEndpointError, Schemas.RegisterLitterEndpointCommand, {}, {}, {}>({
    url: "/api/litter/register",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_register
 */
export const useRegisterLitterEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.LitterResponse, RegisterLitterEndpointError, RegisterLitterEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.LitterResponse, RegisterLitterEndpointError, RegisterLitterEndpointVariables>({
    mutationFn: (variables: RegisterLitterEndpointVariables) => fetchRegisterLitterEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveLitterEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLitterEndpointVariables = {
  body: Schemas.RemoveLitterCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_litter_remove
 */
export const fetchRemoveLitterEndpoint = (variables: RemoveLitterEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveLitterEndpointError, Schemas.RemoveLitterCommand, {}, {}, {}>({
    url: "/api/litter/remove",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_litter_remove
 */
export const useRemoveLitterEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RemoveLitterEndpointError, RemoveLitterEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveLitterEndpointError, RemoveLitterEndpointVariables>({
    mutationFn: (variables: RemoveLitterEndpointVariables) => fetchRemoveLitterEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPrivateMemberContactListEndpointPathParams = {
  /**
   * @format uuid
   */
  memberId: string;
};

export type GetPrivateMemberContactListEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateMemberContactListEndpointResponse = Schemas.MemberContactListView[];

export type GetPrivateMemberContactListEndpointVariables = {
  pathParams: GetPrivateMemberContactListEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_member_memberId_contact_list_private
 */
export const fetchGetPrivateMemberContactListEndpoint = (variables: GetPrivateMemberContactListEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    GetPrivateMemberContactListEndpointResponse,
    GetPrivateMemberContactListEndpointError,
    undefined,
    {},
    {},
    GetPrivateMemberContactListEndpointPathParams
  >({
    url: "/api/member/{memberId}/contact-list/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_member_memberId_contact_list_private
 */
export const useGetPrivateMemberContactListEndpoint = <TData = GetPrivateMemberContactListEndpointResponse>(
  variables: GetPrivateMemberContactListEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPrivateMemberContactListEndpointResponse, GetPrivateMemberContactListEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetPrivateMemberContactListEndpointResponse, GetPrivateMemberContactListEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/member/{memberId}/contact-list/private",
      operationId: "getPrivateMemberContactListEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateMemberContactListEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AllMembersEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AllMembersEndpointResponse = Schemas.MembersView[];

export type AllMembersEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_members
 */
export const fetchAllMembersEndpoint = (variables: AllMembersEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<AllMembersEndpointResponse, AllMembersEndpointError, undefined, {}, {}, {}>({
    url: "/api/members",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_members
 */
export const useAllMembersEndpoint = <TData = AllMembersEndpointResponse>(
  variables: AllMembersEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AllMembersEndpointResponse, AllMembersEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<AllMembersEndpointResponse, AllMembersEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/members",
      operationId: "allMembersEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchAllMembersEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPrivateMemberDetailsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetPrivateMemberDetailsEndpointVariables = HappydogsContext["fetcherOptions"];

/**
 * GET_api_member_private
 */
export const fetchGetPrivateMemberDetailsEndpoint = (variables: GetPrivateMemberDetailsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.MembersView, GetPrivateMemberDetailsEndpointError, undefined, {}, {}, {}>({
    url: "/api/member/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_member_private
 */
export const useGetPrivateMemberDetailsEndpoint = <TData = Schemas.MembersView>(
  variables: GetPrivateMemberDetailsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MembersView, GetPrivateMemberDetailsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.MembersView, GetPrivateMemberDetailsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/member/private",
      operationId: "getPrivateMemberDetailsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPrivateMemberDetailsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAdminMemberDetailsEndpointPathParams = {
  /**
   * @format uuid
   */
  memberId: string;
};

export type GetAdminMemberDetailsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetAdminMemberDetailsEndpointVariables = {
  pathParams: GetAdminMemberDetailsEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_member_memberId_private
 */
export const fetchGetAdminMemberDetailsEndpoint = (variables: GetAdminMemberDetailsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.MembersView, GetAdminMemberDetailsEndpointError, undefined, {}, {}, GetAdminMemberDetailsEndpointPathParams>({
    url: "/api/member/{memberId}/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_member_memberId_private
 */
export const useGetAdminMemberDetailsEndpoint = <TData = Schemas.MembersView>(
  variables: GetAdminMemberDetailsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MembersView, GetAdminMemberDetailsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.MembersView, GetAdminMemberDetailsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/member/{memberId}/private",
      operationId: "getAdminMemberDetailsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetAdminMemberDetailsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetMemberThreadsEndpointPathParams = {
  /**
   * @format uuid
   */
  memberId: string;
};

export type GetMemberThreadsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetMemberThreadsEndpointResponse = Schemas.ThreadView[];

export type GetMemberThreadsEndpointVariables = {
  pathParams: GetMemberThreadsEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_member_memberId_threads_private
 */
export const fetchGetMemberThreadsEndpoint = (variables: GetMemberThreadsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<GetMemberThreadsEndpointResponse, GetMemberThreadsEndpointError, undefined, {}, {}, GetMemberThreadsEndpointPathParams>({
    url: "/api/member/{memberId}/threads/private",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_member_memberId_threads_private
 */
export const useGetMemberThreadsEndpoint = <TData = GetMemberThreadsEndpointResponse>(
  variables: GetMemberThreadsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetMemberThreadsEndpointResponse, GetMemberThreadsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<GetMemberThreadsEndpointResponse, GetMemberThreadsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/member/{memberId}/threads/private",
      operationId: "getMemberThreadsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetMemberThreadsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AnswerMemberQuestionsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AnswerMemberQuestionsEndpointVariables = {
  body: Schemas.AnswerMemberQuestionsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_answer_questions
 */
export const fetchAnswerMemberQuestionsEndpoint = (variables: AnswerMemberQuestionsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AnswerMemberQuestionsEndpointError, Schemas.AnswerMemberQuestionsCommand, {}, {}, {}>({
    url: "/api/member/answer-questions",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_answer_questions
 */
export const useAnswerMemberQuestionsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AnswerMemberQuestionsEndpointError, AnswerMemberQuestionsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AnswerMemberQuestionsEndpointError, AnswerMemberQuestionsEndpointVariables>({
    mutationFn: (variables: AnswerMemberQuestionsEndpointVariables) =>
      fetchAnswerMemberQuestionsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeMemberEmailEndpointError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type ChangeMemberEmailEndpointVariables = {
  body: Schemas.ChangeEmailCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_email
 */
export const fetchChangeMemberEmailEndpoint = (variables: ChangeMemberEmailEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberEmailEndpointError, Schemas.ChangeEmailCommand, {}, {}, {}>({
    url: "/api/member/change-email",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_email
 */
export const useChangeMemberEmailEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeMemberEmailEndpointError, ChangeMemberEmailEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberEmailEndpointError, ChangeMemberEmailEndpointVariables>({
    mutationFn: (variables: ChangeMemberEmailEndpointVariables) => fetchChangeMemberEmailEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeMemberEmailPreferencesEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeMemberEmailPreferencesEndpointVariables = {
  body: Schemas.ChangeMemberEmailPreferencesCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_email_preferences
 */
export const fetchChangeMemberEmailPreferencesEndpoint = (variables: ChangeMemberEmailPreferencesEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberEmailPreferencesEndpointError, Schemas.ChangeMemberEmailPreferencesCommand, {}, {}, {}>({
    url: "/api/member/change-email-preferences",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_email_preferences
 */
export const useChangeMemberEmailPreferencesEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeMemberEmailPreferencesEndpointError, ChangeMemberEmailPreferencesEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberEmailPreferencesEndpointError, ChangeMemberEmailPreferencesEndpointVariables>({
    mutationFn: (variables: ChangeMemberEmailPreferencesEndpointVariables) =>
      fetchChangeMemberEmailPreferencesEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeMemberEmailPreferencesFromSendGridEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeMemberEmailPreferencesFromSendGridEndpointRequestBody = Schemas.WebhookEventRequest[];

export type ChangeMemberEmailPreferencesFromSendGridEndpointVariables = {
  body?: ChangeMemberEmailPreferencesFromSendGridEndpointRequestBody;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_email_preferences_sengrid
 */
export const fetchChangeMemberEmailPreferencesFromSendGridEndpoint = (
  variables: ChangeMemberEmailPreferencesFromSendGridEndpointVariables,
  signal?: AbortSignal,
) =>
  happydogsFetch<
    Schemas.IResult,
    ChangeMemberEmailPreferencesFromSendGridEndpointError,
    ChangeMemberEmailPreferencesFromSendGridEndpointRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/member/change-email-preferences/sengrid",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_email_preferences_sengrid
 */
export const useChangeMemberEmailPreferencesFromSendGridEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IResult,
      ChangeMemberEmailPreferencesFromSendGridEndpointError,
      ChangeMemberEmailPreferencesFromSendGridEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.IResult,
    ChangeMemberEmailPreferencesFromSendGridEndpointError,
    ChangeMemberEmailPreferencesFromSendGridEndpointVariables
  >({
    mutationFn: (variables: ChangeMemberEmailPreferencesFromSendGridEndpointVariables) =>
      fetchChangeMemberEmailPreferencesFromSendGridEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ChangeMemberLocationEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeMemberLocationEndpointVariables = {
  body: Schemas.ChangeMemberLocationCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_location
 */
export const fetchChangeMemberLocationEndpoint = (variables: ChangeMemberLocationEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberLocationEndpointError, Schemas.ChangeMemberLocationCommand, {}, {}, {}>({
    url: "/api/member/change-location",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_location
 */
export const useChangeMemberLocationEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeMemberLocationEndpointError, ChangeMemberLocationEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberLocationEndpointError, ChangeMemberLocationEndpointVariables>({
    mutationFn: (variables: ChangeMemberLocationEndpointVariables) =>
      fetchChangeMemberLocationEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeMemberNameEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeMemberNameEndpointVariables = {
  body: Schemas.ChangeMemberNameCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_name
 */
export const fetchChangeMemberNameEndpoint = (variables: ChangeMemberNameEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberNameEndpointError, Schemas.ChangeMemberNameCommand, {}, {}, {}>({
    url: "/api/member/change-name",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_name
 */
export const useChangeMemberNameEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ChangeMemberNameEndpointError, ChangeMemberNameEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberNameEndpointError, ChangeMemberNameEndpointVariables>({
    mutationFn: (variables: ChangeMemberNameEndpointVariables) => fetchChangeMemberNameEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeMemberPasswordEndpointError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type ChangeMemberPasswordEndpointVariables = {
  body: Schemas.ChangePasswordCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_password
 */
export const fetchChangeMemberPasswordEndpoint = (variables: ChangeMemberPasswordEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberPasswordEndpointError, Schemas.ChangePasswordCommand, {}, {}, {}>({
    url: "/api/member/change-password",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_password
 */
export const useChangeMemberPasswordEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeMemberPasswordEndpointError, ChangeMemberPasswordEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberPasswordEndpointError, ChangeMemberPasswordEndpointVariables>({
    mutationFn: (variables: ChangeMemberPasswordEndpointVariables) =>
      fetchChangeMemberPasswordEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeMemberRolesEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeMemberRolesEndpointVariables = {
  body: Schemas.ChangeMemberRolesCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_roles
 */
export const fetchChangeMemberRolesEndpoint = (variables: ChangeMemberRolesEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberRolesEndpointError, Schemas.ChangeMemberRolesCommand, {}, {}, {}>({
    url: "/api/member/change-roles",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_roles
 */
export const useChangeMemberRolesEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeMemberRolesEndpointError, ChangeMemberRolesEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberRolesEndpointError, ChangeMemberRolesEndpointVariables>({
    mutationFn: (variables: ChangeMemberRolesEndpointVariables) => fetchChangeMemberRolesEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RequestBreederRoleEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RequestBreederRoleEndpointVariables = {
  body: Schemas.RequestBreederRoleCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_request_breeder_role
 */
export const fetchRequestBreederRoleEndpoint = (variables: RequestBreederRoleEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RequestBreederRoleEndpointError, Schemas.RequestBreederRoleCommand, {}, {}, {}>({
    url: "/api/member/request-breeder-role",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_request_breeder_role
 */
export const useRequestBreederRoleEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RequestBreederRoleEndpointError, RequestBreederRoleEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RequestBreederRoleEndpointError, RequestBreederRoleEndpointVariables>({
    mutationFn: (variables: RequestBreederRoleEndpointVariables) => fetchRequestBreederRoleEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RequestPilotRoleEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RequestPilotRoleEndpointVariables = {
  body: Schemas.RequestPilotRoleCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_request_pilot_role
 */
export const fetchRequestPilotRoleEndpoint = (variables: RequestPilotRoleEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RequestPilotRoleEndpointError, Schemas.RequestPilotRoleCommand, {}, {}, {}>({
    url: "/api/member/request-pilot-role",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_request_pilot_role
 */
export const useRequestPilotRoleEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RequestPilotRoleEndpointError, RequestPilotRoleEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RequestPilotRoleEndpointError, RequestPilotRoleEndpointVariables>({
    mutationFn: (variables: RequestPilotRoleEndpointVariables) => fetchRequestPilotRoleEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangeMemberTelephoneEndpointError = Fetcher.ErrorWrapper<undefined>;

export type ChangeMemberTelephoneEndpointVariables = {
  body: Schemas.ChangeMemberTelephoneCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_change_telephone
 */
export const fetchChangeMemberTelephoneEndpoint = (variables: ChangeMemberTelephoneEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, ChangeMemberTelephoneEndpointError, Schemas.ChangeMemberTelephoneCommand, {}, {}, {}>({
    url: "/api/member/change-telephone",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_change_telephone
 */
export const useChangeMemberTelephoneEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ChangeMemberTelephoneEndpointError, ChangeMemberTelephoneEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, ChangeMemberTelephoneEndpointError, ChangeMemberTelephoneEndpointVariables>({
    mutationFn: (variables: ChangeMemberTelephoneEndpointVariables) =>
      fetchChangeMemberTelephoneEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateMemberUploadAssetTokenEndpointError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type CreateMemberUploadAssetTokenEndpointVariables = {
  body: Schemas.CreateUploadAssetTokenCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_create_upload_asset_token
 */
export const fetchCreateMemberUploadAssetTokenEndpoint = (variables: CreateMemberUploadAssetTokenEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.CreateUploadAssetResult,
    CreateMemberUploadAssetTokenEndpointError,
    Schemas.CreateUploadAssetTokenCommand,
    {},
    {},
    {}
  >({
    url: "/api/member/create-upload-asset-token",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_create_upload_asset_token
 */
export const useCreateMemberUploadAssetTokenEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CreateUploadAssetResult,
      CreateMemberUploadAssetTokenEndpointError,
      CreateMemberUploadAssetTokenEndpointVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<
    Schemas.CreateUploadAssetResult,
    CreateMemberUploadAssetTokenEndpointError,
    CreateMemberUploadAssetTokenEndpointVariables
  >({
    mutationFn: (variables: CreateMemberUploadAssetTokenEndpointVariables) =>
      fetchCreateMemberUploadAssetTokenEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type AddMemberUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type AddMemberUploadedImageEndpointVariables = {
  body: Schemas.AddMemberUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_add_uploaded_image
 */
export const fetchAddMemberUploadedImageEndpoint = (variables: AddMemberUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AddMemberUploadedImageEndpointError, Schemas.AddMemberUploadedImageCommand, {}, {}, {}>({
    url: "/api/member/add-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_add_uploaded_image
 */
export const useAddMemberUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AddMemberUploadedImageEndpointError, AddMemberUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AddMemberUploadedImageEndpointError, AddMemberUploadedImageEndpointVariables>({
    mutationFn: (variables: AddMemberUploadedImageEndpointVariables) =>
      fetchAddMemberUploadedImageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveMemberUploadedImageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RemoveMemberUploadedImageEndpointVariables = {
  body: Schemas.RemoveMemberUploadedImageCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_remove_uploaded_image
 */
export const fetchRemoveMemberUploadedImageEndpoint = (variables: RemoveMemberUploadedImageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, RemoveMemberUploadedImageEndpointError, Schemas.RemoveMemberUploadedImageCommand, {}, {}, {}>({
    url: "/api/member/remove-uploaded-image",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_remove_uploaded_image
 */
export const useRemoveMemberUploadedImageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, RemoveMemberUploadedImageEndpointError, RemoveMemberUploadedImageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, RemoveMemberUploadedImageEndpointError, RemoveMemberUploadedImageEndpointVariables>({
    mutationFn: (variables: RemoveMemberUploadedImageEndpointVariables) =>
      fetchRemoveMemberUploadedImageEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type RequestMemberPilotAccessEndpointError = Fetcher.ErrorWrapper<undefined>;

export type RequestMemberPilotAccessEndpointVariables = {
  body: Schemas.RequestPilotAccessCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_request_pilot_access
 */
export const fetchRequestMemberPilotAccessEndpoint = (variables: RequestMemberPilotAccessEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.IResult, RequestMemberPilotAccessEndpointError, Schemas.RequestPilotAccessCommand, {}, {}, {}>({
    url: "/api/member/request-pilot-access",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_request_pilot_access
 */
export const useRequestMemberPilotAccessEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.IResult, RequestMemberPilotAccessEndpointError, RequestMemberPilotAccessEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.IResult, RequestMemberPilotAccessEndpointError, RequestMemberPilotAccessEndpointVariables>({
    mutationFn: (variables: RequestMemberPilotAccessEndpointVariables) =>
      fetchRequestMemberPilotAccessEndpoint({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type SignupMemberWithClerkEndpointError = Fetcher.ErrorWrapper<undefined>;

export type SignupMemberWithClerkEndpointVariables = {
  body: Schemas.UserCreatedEvent;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_clerk_signup
 */
export const fetchSignupMemberWithClerkEndpoint = (variables: SignupMemberWithClerkEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, SignupMemberWithClerkEndpointError, Schemas.UserCreatedEvent, {}, {}, {}>({
    url: "/api/member/clerk/signup",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_clerk_signup
 */
export const useSignupMemberWithClerkEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SignupMemberWithClerkEndpointError, SignupMemberWithClerkEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, SignupMemberWithClerkEndpointError, SignupMemberWithClerkEndpointVariables>({
    mutationFn: (variables: SignupMemberWithClerkEndpointVariables) =>
      fetchSignupMemberWithClerkEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type VerifyMemberWithVippsEndpointError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.ProblemDetails;
    }
>;

export type VerifyMemberWithVippsEndpointVariables = {
  body: Schemas.VerifyMemberWithVippsCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_member_verify_vipps
 */
export const fetchVerifyMemberWithVippsEndpoint = (variables: VerifyMemberWithVippsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, VerifyMemberWithVippsEndpointError, Schemas.VerifyMemberWithVippsCommand, {}, {}, {}>({
    url: "/api/member/verify/vipps",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_member_verify_vipps
 */
export const useVerifyMemberWithVippsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, VerifyMemberWithVippsEndpointError, VerifyMemberWithVippsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, VerifyMemberWithVippsEndpointError, VerifyMemberWithVippsEndpointVariables>({
    mutationFn: (variables: VerifyMemberWithVippsEndpointVariables) =>
      fetchVerifyMemberWithVippsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetNotificationsEndpointPathParams = {
  /**
   * @format uuid
   */
  memberId: string;
};

export type GetNotificationsEndpointError = Fetcher.ErrorWrapper<undefined>;

export type GetNotificationsEndpointVariables = {
  pathParams: GetNotificationsEndpointPathParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_notifications_read_memberId
 */
export const fetchGetNotificationsEndpoint = (variables: GetNotificationsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.NotificationsResponse, GetNotificationsEndpointError, undefined, {}, {}, GetNotificationsEndpointPathParams>({
    url: "/api/notifications/read/{memberId}",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_notifications_read_memberId
 */
export const useGetNotificationsEndpoint = <TData = Schemas.NotificationsResponse>(
  variables: GetNotificationsEndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.NotificationsResponse, GetNotificationsEndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.NotificationsResponse, GetNotificationsEndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/notifications/read/{memberId}",
      operationId: "getNotificationsEndpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchGetNotificationsEndpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MarkNotificationsReadEndpointError = Fetcher.ErrorWrapper<undefined>;

export type MarkNotificationsReadEndpointVariables = {
  body: Schemas.MarkNotificationReadCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_notifications_mark_read_
 */
export const fetchMarkNotificationsReadEndpoint = (variables: MarkNotificationsReadEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, MarkNotificationsReadEndpointError, Schemas.MarkNotificationReadCommand, {}, {}, {}>({
    url: "/api/notifications/mark-read",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_notifications_mark_read_
 */
export const useMarkNotificationsReadEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, MarkNotificationsReadEndpointError, MarkNotificationsReadEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, MarkNotificationsReadEndpointError, MarkNotificationsReadEndpointVariables>({
    mutationFn: (variables: MarkNotificationsReadEndpointVariables) =>
      fetchMarkNotificationsReadEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AdminSendNotificationsEndpointError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type AdminSendNotificationsEndpointVariables = {
  body: Schemas.AdminSendNotificationCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_notifications_admin_send
 */
export const fetchAdminSendNotificationsEndpoint = (variables: AdminSendNotificationsEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, AdminSendNotificationsEndpointError, Schemas.AdminSendNotificationCommand, {}, {}, {}>({
    url: "/api/notifications/admin-send",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_notifications_admin_send
 */
export const useAdminSendNotificationsEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, AdminSendNotificationsEndpointError, AdminSendNotificationsEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, AdminSendNotificationsEndpointError, AdminSendNotificationsEndpointVariables>({
    mutationFn: (variables: AdminSendNotificationsEndpointVariables) =>
      fetchAdminSendNotificationsEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchV1EndpointQueryParams = {
  query?: string;
  includeDogs?: boolean;
  includeBreeders?: boolean;
  includeLitters?: boolean;
};

export type SearchV1EndpointError = Fetcher.ErrorWrapper<undefined>;

export type SearchV1EndpointVariables = {
  queryParams?: SearchV1EndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_search_v1
 */
export const fetchSearchV1Endpoint = (variables: SearchV1EndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.SearchV1Response, SearchV1EndpointError, undefined, {}, SearchV1EndpointQueryParams, {}>({
    url: "/api/search/v1",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_search_v1
 */
export const useSearchV1Endpoint = <TData = Schemas.SearchV1Response>(
  variables: SearchV1EndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SearchV1Response, SearchV1EndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<Schemas.SearchV1Response, SearchV1EndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/search/v1",
      operationId: "searchV1Endpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchSearchV1Endpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SearchV2EndpointQueryParams = {
  query?: string;
  locations?: string;
  breeds?: string;
  includeDogs?: boolean;
  includeBreeders?: boolean;
  includeLitters?: boolean;
  dogIsMale?: boolean;
  dogIsAvailableForBreeder?: boolean;
  healthResults?: string;
  litterFollowsNkkGuidelines?: boolean;
  litterFollowsClubGuidelines?: boolean;
  litterIsAvailable?: boolean;
  breederPlannedLitters?: boolean;
  breederLittersAvailable?: boolean;
};

export type SearchV2EndpointError = Fetcher.ErrorWrapper<undefined>;

export type SearchV2EndpointResponse = Schemas.SearchV2Response[];

export type SearchV2EndpointVariables = {
  queryParams?: SearchV2EndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * GET_api_search_v2
 */
export const fetchSearchV2Endpoint = (variables: SearchV2EndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<SearchV2EndpointResponse, SearchV2EndpointError, undefined, {}, SearchV2EndpointQueryParams, {}>({
    url: "/api/search/v2",
    method: "get",
    ...variables,
    signal,
  });

/**
 * GET_api_search_v2
 */
export const useSearchV2Endpoint = <TData = SearchV2EndpointResponse>(
  variables: SearchV2EndpointVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SearchV2EndpointResponse, SearchV2EndpointError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useHappydogsContext(options);
  return reactQuery.useQuery<SearchV2EndpointResponse, SearchV2EndpointError, TData>({
    queryKey: queryKeyFn({
      path: "/api/search/v2",
      operationId: "searchV2Endpoint",
      variables,
    }),
    queryFn: ({ signal }) => fetchSearchV2Endpoint({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MessageReadEndpointError = Fetcher.ErrorWrapper<undefined>;

export type MessageReadEndpointVariables = {
  body: Schemas.MessageReadCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_thread_message_read
 */
export const fetchMessageReadEndpoint = (variables: MessageReadEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<undefined, MessageReadEndpointError, Schemas.MessageReadCommand, {}, {}, {}>({
    url: "/api/thread/message-read",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_thread_message_read
 */
export const useMessageReadEndpoint = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, MessageReadEndpointError, MessageReadEndpointVariables>, "mutationFn">,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<undefined, MessageReadEndpointError, MessageReadEndpointVariables>({
    mutationFn: (variables: MessageReadEndpointVariables) => fetchMessageReadEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type StartThreadEndpointError = Fetcher.ErrorWrapper<undefined>;

export type StartThreadEndpointVariables = {
  body: Schemas.StartThreadEndpointCommand;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_thread_start
 */
export const fetchStartThreadEndpoint = (variables: StartThreadEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<Schemas.ThreadResponse, StartThreadEndpointError, Schemas.StartThreadEndpointCommand, {}, {}, {}>({
    url: "/api/thread/start",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_thread_start
 */
export const useStartThreadEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.ThreadResponse, StartThreadEndpointError, StartThreadEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.ThreadResponse, StartThreadEndpointError, StartThreadEndpointVariables>({
    mutationFn: (variables: StartThreadEndpointVariables) => fetchStartThreadEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SendThreadTextMessageEndpointQueryParams = {
  /**
   * @format uuid
   */
  threadId?: string;
};

export type SendThreadTextMessageEndpointError = Fetcher.ErrorWrapper<undefined>;

export type SendThreadTextMessageEndpointVariables = {
  body: Schemas.SendThreadTextMessageEndpointCommand;
  queryParams?: SendThreadTextMessageEndpointQueryParams;
} & HappydogsContext["fetcherOptions"];

/**
 * POST_api_chat_thread_send_text_message
 */
export const fetchSendThreadTextMessageEndpoint = (variables: SendThreadTextMessageEndpointVariables, signal?: AbortSignal) =>
  happydogsFetch<
    Schemas.ThreadResponse,
    SendThreadTextMessageEndpointError,
    Schemas.SendThreadTextMessageEndpointCommand,
    {},
    SendThreadTextMessageEndpointQueryParams,
    {}
  >({
    url: "/api/chat/thread/send-text-message",
    method: "post",
    ...variables,
    signal,
  });

/**
 * POST_api_chat_thread_send_text_message
 */
export const useSendThreadTextMessageEndpoint = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.ThreadResponse, SendThreadTextMessageEndpointError, SendThreadTextMessageEndpointVariables>,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useHappydogsContext();
  return reactQuery.useMutation<Schemas.ThreadResponse, SendThreadTextMessageEndpointError, SendThreadTextMessageEndpointVariables>({
    mutationFn: (variables: SendThreadTextMessageEndpointVariables) =>
      fetchSendThreadTextMessageEndpoint({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: "/api/application/statistics";
      operationId: "statisticsEndpoint";
      variables: StatisticsEndpointVariables;
    }
  | {
      path: "/api/application/support-requests/bugs";
      operationId: "allBugsEndpoints";
      variables: AllBugsEndpointsVariables;
    }
  | {
      path: "/api/application/support-requests/other";
      operationId: "allSupportRequestsEndpoint";
      variables: AllSupportRequestsEndpointVariables;
    }
  | {
      path: "/api/application/support-requests/suggestions";
      operationId: "allSuggestionsEndpoint";
      variables: AllSuggestionsEndpointVariables;
    }
  | {
      path: "/api/health/tests";
      operationId: "healthTestEndpoint";
      variables: HealthTestEndpointVariables;
    }
  | {
      path: "/api/article/all/public";
      operationId: "allArticlesEndpoint";
      variables: AllArticlesEndpointVariables;
    }
  | {
      path: "/api/breeders/public";
      operationId: "allBreedersEndpoint";
      variables: AllBreedersEndpointVariables;
    }
  | {
      path: "/api/breeders/randomized/public";
      operationId: "allBreedersRandomizedEndpoint";
      variables: AllBreedersRandomizedEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederId}/private";
      operationId: "getPrivateBreederEndpoint";
      variables: GetPrivateBreederEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederSlug}/public";
      operationId: "getBreederBySlugEndpoint";
      variables: GetBreederBySlugEndpointVariables;
    }
  | {
      path: "/api/breeder/slug-list";
      operationId: "getBreederSlugListEndpoint";
      variables: GetBreederSlugListEndpointVariables;
    }
  | {
      path: "/api/member/{memberId}/breeder/private";
      operationId: "getPrivateMemberBreederEndpoint";
      variables: GetPrivateMemberBreederEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederId}/contact-list/private";
      operationId: "getPrivateContactListEndpoint";
      variables: GetPrivateContactListEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederId}/private/images";
      operationId: "getPrivateBreederImages";
      variables: GetPrivateBreederImagesVariables;
    }
  | {
      path: "/api/dog/{dogId}/private";
      operationId: "privateDogEndpoint";
      variables: PrivateDogEndpointVariables;
    }
  | {
      path: "/api/member/{memberId}/dogs/private";
      operationId: "getPrivateMemberDogsEndpoint";
      variables: GetPrivateMemberDogsEndpointVariables;
    }
  | {
      path: "/api/dogs/public";
      operationId: "allDogsEndpoint";
      variables: AllDogsEndpointVariables;
    }
  | {
      path: "/api/dogs/randomized/public";
      operationId: "allDogsRandomizedEndpoint";
      variables: AllDogsRandomizedEndpointVariables;
    }
  | {
      path: "/api/admin/unverified-dogs";
      operationId: "allUnverifiedDogsEndpoint";
      variables: AllUnverifiedDogsEndpointVariables;
    }
  | {
      path: "/api/dog/slug-list";
      operationId: "getDogSlugListEndpoint";
      variables: GetDogSlugListEndpointVariables;
    }
  | {
      path: "/api/dog/{dogSlug}/public";
      operationId: "getDogBySlugEndpoint";
      variables: GetDogBySlugEndpointVariables;
    }
  | {
      path: "/api/dog/{registryId}/by/registry/id/public";
      operationId: "getDogByRegistryIdEndpoint";
      variables: GetDogByRegistryIdEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederSlug}/dogs/public";
      operationId: "getPublicDogByBreederSlugEndpoint";
      variables: GetPublicDogByBreederSlugEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederId}/private/litters";
      operationId: "getPrivateBreederLittersDetailsEndpoint";
      variables: GetPrivateBreederLittersDetailsEndpointVariables;
    }
  | {
      path: "/api/litter/{litterId}/private";
      operationId: "getPrivateLitterDetailsEndpoint";
      variables: GetPrivateLitterDetailsEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederSlug}/litters/{litterSlug}/public";
      operationId: "getPublicLitterBySlugEndpoint";
      variables: GetPublicLitterBySlugEndpointVariables;
    }
  | {
      path: "/api/breeder/{breederSlug}/litters/public";
      operationId: "getPublicLitterByBreederSlugEndpoint";
      variables: GetPublicLitterByBreederSlugEndpointVariables;
    }
  | {
      path: "/api/litters/public";
      operationId: "allLittersEndpoint";
      variables: AllLittersEndpointVariables;
    }
  | {
      path: "/api/litters/randomized/public";
      operationId: "allLittersRandomizedEndpoint";
      variables: AllLittersRandomizedEndpointVariables;
    }
  | {
      path: "/api/member/{memberId}/contact-list/private";
      operationId: "getPrivateMemberContactListEndpoint";
      variables: GetPrivateMemberContactListEndpointVariables;
    }
  | {
      path: "/api/members";
      operationId: "allMembersEndpoint";
      variables: AllMembersEndpointVariables;
    }
  | {
      path: "/api/member/private";
      operationId: "getPrivateMemberDetailsEndpoint";
      variables: GetPrivateMemberDetailsEndpointVariables;
    }
  | {
      path: "/api/member/{memberId}/private";
      operationId: "getAdminMemberDetailsEndpoint";
      variables: GetAdminMemberDetailsEndpointVariables;
    }
  | {
      path: "/api/member/{memberId}/threads/private";
      operationId: "getMemberThreadsEndpoint";
      variables: GetMemberThreadsEndpointVariables;
    }
  | {
      path: "/api/notifications/read/{memberId}";
      operationId: "getNotificationsEndpoint";
      variables: GetNotificationsEndpointVariables;
    }
  | {
      path: "/api/search/v1";
      operationId: "searchV1Endpoint";
      variables: SearchV1EndpointVariables;
    }
  | {
      path: "/api/search/v2";
      operationId: "searchV2Endpoint";
      variables: SearchV2EndpointVariables;
    };
